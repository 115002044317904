import MultiValueDisplay from "components/MultiValueDisplay";
import { StaffSportsCard } from "models/cards";
import { StaffPackage } from "models/packages";
import { FC } from "react";
import { isNewSilentUser } from "util/helpers";

const CustomerDisplay: FC<StaffPackage | StaffSportsCard> = ({
    customerFirstName,
    customerLastName,
    customerEmail,
    customerUserName,
    silentUser
}) => {
    if (!silentUser) {
        return <MultiValueDisplay
            title={`${customerFirstName} ${customerLastName}`}
            subTitle={customerEmail}
        />
    } else if (isNewSilentUser(silentUser)) {
        return <MultiValueDisplay
            title={`${silentUser.userName}`}
            subTitle={`via ${customerUserName}`}
        />
    }

    return <MultiValueDisplay
        title={`${silentUser.firstName} ${silentUser.lastName}`}
        subTitle={`via ${customerUserName}`}
    />
}

export default CustomerDisplay;