import { Box, Flex, HStack } from "@chakra-ui/react";
import { Button, Descriptions, Space } from "antd";
import { StaffRoutes } from "appRoutePaths";
import { AccountType } from "models/accountSummary";
import { PackageAmount, StaffPackage } from "models/packages";
import { PackageQRCodeLink } from "pages/staff/NewPackage";
import { Link } from "react-router-dom";
import { isNewSilentUser, localizedDate } from "util/helpers";

const CreatedPackageDetail = (props: {
    prevPackage?: StaffPackage;
    package: StaffPackage;
    onAddNext: () => void;
}) => {
    const { package: pkg } = props;
    const isSilent = pkg.accountType === AccountType.Silent;

    return (
        <Flex flexDirection="column">
            <Flex py={3} justifyContent='space-between'>
                <div>
                    <PackageQRCodeLink id={pkg.id} />
                </div>
                <Space>
                    ID:
                    <Box fontWeight="semibold" pl={2}>
                        {pkg.friendlyId}
                    </Box>
                </Space>
            </Flex>
            <Descriptions layout="horizontal" column={1} bordered>
                <Descriptions.Item
                    label={isSilent ? "Breaker Name:" : "Customer Name:"}
                    style={{ fontSize: 18 }}
                >
                    {pkg.customerFirstName} {pkg.customerLastName}
                </Descriptions.Item>
                {isSilent && <Descriptions.Item
                    label="Customer Name:"
                    style={{ fontSize: 18 }}
                >
                    {isNewSilentUser(pkg.silentUser!)
                        ? pkg.silentUser?.userName
                        : `${pkg.silentUser!.firstName} ${pkg.silentUser!.lastName}`}
                </Descriptions.Item>}
                <Descriptions.Item
                    label="Package Type:"
                    style={{ fontSize: 18 }}
                >
                    {pkg.packageTypeDisplayName}
                </Descriptions.Item>
                <Descriptions.Item label="Amount:" style={{ fontSize: 18 }}>
                    {PackageAmount[pkg.packageAmount]}
                </Descriptions.Item>
                <Descriptions.Item
                    label="Date Received:"
                    style={{ fontSize: 18 }}
                >
                    {localizedDate(pkg.receivedDate)}
                </Descriptions.Item>
                <Descriptions.Item
                    label="Est. Upload Date:"
                    style={{ fontSize: 18 }}
                >
                    {localizedDate(pkg.estimatedUploadDate)}
                </Descriptions.Item>
            </Descriptions>
            <HStack pt={4} spacing={6} alignSelf="flex-end">
                {props.prevPackage && <PackageQRCodeLink id={props.prevPackage.id} text="Prev. QR Code" />}
                <Button size="large">
                    <Link to={StaffRoutes.packages.url}>Close</Link>
                </Button>
                <Button size="large" type="primary" onClick={props.onAddNext}>
                    Add Next
                </Button>
            </HStack>
        </Flex>
    );
};

export default CreatedPackageDetail;
