import { UserOutlined } from "@ant-design/icons";
import { Card, Tabs } from "antd";
import BreakerCards from "components/breaker/Cards";
import BreakerCustomers from "components/breaker/Customers";
import { useBreakerPortalContext } from "components/breaker/PortalContext";
import BreakerSubmissions from "components/breaker/Submissions";
import BreakerTransactions from "components/breaker/Transactions";
import { FC, ReactNode } from "react";
import { useSearchParams } from "react-router-dom";
import "styles/breaker.css";

const packagesIcon = require("images/icons/icon_packages.svg");
const cardsIcon = require("images/icons/icon_cards.svg");
const transactionIcon = require("images/icons/icon_transactions.svg");

const IconLabel: FC<{ icon: ReactNode, label: string }> = ({ icon, label }) => {
    return <span>
        {icon}
        {label}
    </span>
}

// this component is so the current color is 
// respected for SVG based icons when navigating  
// tabs, while emulating ant.design's native icons
const Svg: FC<{ icon: any }> = ({ icon }) =>
    <div style={{
        display: 'inline-block',
        width: '1em',
        height: '1em',
        verticalAlign: '-0.125em',
        marginRight: '12px',
        maskSize: '100%',
        WebkitMaskRepeat: 'no-repeat',
        maskRepeat: 'no-repeat',
        maskPosition: 'center',
        maskImage: `url(${icon.default})`,
        WebkitMaskImage: `url(${icon.default})`,
        backgroundColor: 'currentColor'
    }} />;


const validTabKeys = [
    'customers',
    'submissions',
    'cards',
    'transactions'
] as const;

type TabKey = typeof validTabKeys[number];

const isValidTabKey = (value: string | null): value is TabKey =>
    validTabKeys.includes(value as TabKey);

const items = [
    {
        label: <IconLabel label="Customers" icon={<UserOutlined />} />,
        key: validTabKeys[0],
        children: <BreakerCustomers />
    },
    {
        label: <IconLabel label="Submissions" icon={<Svg icon={packagesIcon} />} />,
        key: validTabKeys[1],
        children: <BreakerSubmissions />
    },
    {
        label: <IconLabel label="Cards" icon={<Svg icon={cardsIcon} />} />,
        key: validTabKeys[2],
        children: <BreakerCards />
    },
    {
        label: <IconLabel label="Transactions" icon={<Svg icon={transactionIcon} />} />,
        key: validTabKeys[3],
        children: <BreakerTransactions />
    },
];

const BreakerPortal = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    // child components can use this hook to set custom content to be 
    // displayed in the tab bar. currently used in BreakerTable.
    const { content } = useBreakerPortalContext();

    const requestedKey = searchParams.get('t');
    const selectedKey = isValidTabKey(requestedKey)
        ? requestedKey
        : undefined;

    return <Card className="breaker-tabs-container">
        <Tabs
            size="large"
            items={items}
            defaultActiveKey={selectedKey}
            onChange={(t) => setSearchParams({ t })}
            tabBarExtraContent={content}
            destroyInactiveTabPane
        />
    </Card>;
};

export default BreakerPortal;