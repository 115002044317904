import { Box, VStack } from "@chakra-ui/react";
import { Button, Divider } from "antd";
import { StaffRoutes } from "appRoutePaths";
import CreatedPackageDetail from "components/staff/Packages/CreatedPackageDetail";
import NewPackageForm, {
    NewPackageFormData,
} from "components/staff/Packages/NewPackageForm";
import useCreatePackage from "hooks/useCreatePackage";
import { ApplicationUser } from "models/applicationUsers";
import { PackageType, StaffPackage } from "models/packages";
import { useState } from "react";
import { Link } from "react-router-dom";

export const PackageQRCodeLink = ({ id, text = "QR Code" }: { id: string, text?: string }) =>
    <Button size="large" type="link">
        <Link to={StaffRoutes.packageQRCode.url(id)} target="_blank" rel="noreferrer">{text}</Link>
    </Button>;

const NewPackage = () => {
    const [createdPackage, setCreatedPackage] = useState<StaffPackage>();
    const [prevPackage, setPrevPackage] = useState<StaffPackage>();
    const { createPackage, working } = useCreatePackage();
    const [packageType, setPackageType] = useState<PackageType | undefined>();
    const [customer, setCustomer] = useState<ApplicationUser | undefined>();

    const submitForm = async (formData: NewPackageFormData) => {
        const data = await createPackage(formData);
        setCreatedPackage(data);

        if (formData.packageType.supportSilentAccounts) {
            setPackageType(formData.packageType);
            setCustomer(formData.customer);
        } else {
            setPackageType(undefined);
            setCustomer(undefined);
        }
    };

    const resetPage = () => {
        setPrevPackage(createdPackage);
        setCreatedPackage(undefined);
    };

    return (
        <VStack p={50} align="start" fontSize="1.6rem">
            <Box fontSize={36}>Add New Package</Box>
            <Divider />
            <Box fontSize={26}>Package Information</Box>
            {createdPackage ? (
                <CreatedPackageDetail
                    prevPackage={prevPackage}
                    package={createdPackage}
                    onAddNext={resetPage}
                />
            ) : (
                <NewPackageForm
                    prevPackage={prevPackage}
                    disabled={working}
                    packageType={packageType}
                    customer={customer}
                    onSubmit={submitForm}
                />
            )}
        </VStack>
    );
};

export default NewPackage;
