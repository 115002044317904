import { Empty, Pagination, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue } from "antd/lib/table/interface";
import { BaseEntity, PageRequest, PageSizeOptions } from "models/common";
import { useCallback, useEffect, useState } from "react";
import { DefaultPageRequest } from "util/getDefaultPageRequest";
import CsvExport from "../CsvExport";
import { BreakerHooks } from "./createBreakerHooks";
import { useBreakerPortalContext } from "./PortalContext";

interface BreakerTableProps<T extends BaseEntity> {
    hooks: BreakerHooks<T>;
    columns: ColumnsType<T>;
    noDataText: string;
    initialPageRequest?: Partial<PageRequest>;
    defaultPageSize?: number
    onTableChanged?: () => void;
}

const BreakerTable = <T extends BaseEntity>(
    { hooks, columns, noDataText, initialPageRequest = {}, defaultPageSize, onTableChanged }: BreakerTableProps<T>
) => {
    const [useDataHook, useCSVHook] = hooks;

    const { setContent } = useBreakerPortalContext();
    const [{ page, pageSize, filter, filterBy, withinDays, sort }, setPageRequest] =
        useState<PageRequest>(() => ({
            ...DefaultPageRequest,
            ...initialPageRequest,
            pageSize: defaultPageSize ?? PageSizeOptions[0]
        }));
    const { isLoading, data } = useDataHook({ page, pageSize, filter, filterBy, withinDays, sort });
    const { data: records, totalRecords } = data || {};
    const fetchCSV = useCSVHook(filterBy);

    const filterOrSortChanged = (
        _: any,
        filters: Record<string, FilterValue | null>,
        { column, field, order }: any
    ) => {
        setPageRequest({
            page: 1,
            pageSize,
            filter,
            filterBy: filters,
            withinDays,
            sort: column ? [field, order === "ascend"] : null,
        });

        if (onTableChanged) {
            onTableChanged();
        }
    };

    const paginationChanged = useCallback((newPage: number, newPageSize: number) => {
        setPageRequest({
            page: newPageSize !== pageSize ? 1 : newPage,
            pageSize: newPageSize,
            filter,
            filterBy,
            withinDays,
            sort
        });

        if (onTableChanged) {
            onTableChanged();
        }
    }, [onTableChanged, pageSize, filter, filterBy, withinDays, sort]);

    useEffect(() => {
        setContent(<Space direction="vertical">
            <CsvExport
                onExport={fetchCSV}
                disabled={isLoading}
            />
            <Pagination
                size="small"
                total={totalRecords}
                current={page}
                pageSize={pageSize}
                pageSizeOptions={PageSizeOptions}
                showSizeChanger
                onChange={paginationChanged}
            />
        </Space>);
    }, [setContent, fetchCSV, isLoading, totalRecords, page, pageSize, paginationChanged]);

    const emptyText = filterBy === undefined
        ? noDataText
        : "No records match your filters!";

    return <Table
        size="small"
        loading={isLoading}
        dataSource={records}
        rowKey={r => r.id}
        columns={columns}
        onChange={filterOrSortChanged}
        pagination={false}
        locale={{ emptyText: <Empty description={emptyText} /> }}
    />;
};

export default BreakerTable;