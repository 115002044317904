import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload, UploadFile, UploadProps } from "antd";
import axios from "axios";
import { CardPhotoType, CardPhotoTypeNames } from "models/cardIngestion";
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useEffect, useState } from "react";
import { useCardImageUpload } from "./useCardRelistHooks";

export interface UploadCardImagesProps {
    defaults?: string[];
    cardId: string;
    type: CardPhotoType;
    onAdded?: (result: CardImageUploadResult) => void;
    onRemove?: (uid: string) => void;
    onPreview?: (file: UploadFile) => void;
    disabled?: boolean;
}

export interface CardImageUploadResult {
    url?: string;
    type: CardPhotoType;
    ok: boolean;
    uid: string;
}

const getOptionsFromType = (type: CardPhotoType) => {
    switch (type) {
        case CardPhotoType.Front:
            return { caption: "Front Image", max: 1 };
        case CardPhotoType.Back:
            return { caption: "Back Image", max: 1 };
        case CardPhotoType.Extra:
            return { caption: "Extra Images", max: 4 };
        default:
            return { caption: "", max: 0 };
    }
}

const imageUrlsToUploadFiles = (urls: string[]): UploadFile[] => {
    return urls.map((url: string) => ({
        uid: `default-image-${crypto.randomUUID()}`,
        status: 'done',
        name: url.substring(url.lastIndexOf('/') + 1),
        url,
    }))
}

const UploadCardImages = ({ cardId, type, onAdded, onRemove, defaults = [], disabled = false, onPreview }: UploadCardImagesProps) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [urlMap, setUrlMap] = useState<{ uid: string, url: string }[]>(() => []);
    const { caption, max } = getOptionsFromType(type);
    const { uploadImage, removeImage, isUploading } = useCardImageUpload();

    useEffect(() => {
        if (defaults.length > 0) {
            const initial = imageUrlsToUploadFiles(defaults);
            setFileList(initial);
            initial.forEach(x => {
                onAdded && onAdded({ ...x, ok: true, type });
            });
        }
    }, [type, defaults, setFileList, onAdded]);

    const uploadButton = (
        <div>
            {isUploading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>{caption}</div>
        </div>
    );

    const uploadFile = async (options: UploadRequestOption) => {
        const { onError } = options;
        const uid = (options.file as UploadFile).uid;

        try {
            const result = await uploadImage({ cardId, type, options });
            setUrlMap(cur => [...cur, { uid, url: result.url }]);
            onAdded && onAdded({ uid, ok: true, ...result });
        } catch (err) {
            const errObj = axios.isAxiosError(err)
                ? { ...err, status: Number(err.status) }
                : {
                    status: 400,
                    name: '',
                    message: 'upload failed'
                };
            onError && onError(errObj, 'Unsupported image format!');
            onAdded && onAdded({ uid, type, ok: false });
        }
    }

    const removeFile = async (file: UploadFile) => {
        onRemove && onRemove(file.uid);
        const item = urlMap.find(x => x.uid === file.uid);
        if (item) {
            setUrlMap(cur => [...cur.filter(x => x.uid !== file.uid)]);
            await removeImage(item.url);
        }
    }

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    }

    return <>
        <div style={{ textAlign: 'center', marginLeft: '-6px' }}>
            {CardPhotoTypeNames[type]}
        </div>
        <Upload
            accept=".jpg,.jpeg,.png"
            customRequest={uploadFile}
            listType="picture-card"
            disabled={disabled}
            fileList={fileList}
            onChange={handleChange}
            onRemove={removeFile}
            onPreview={onPreview}
        >
            {fileList.length >= max ? null : uploadButton}
        </Upload>
    </>
};

export default UploadCardImages;