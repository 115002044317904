import { useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { useAuthenticatedRequest } from "hooks/useRequests";
import { CustomerQueryKeySegment, TransactionQueryKeySegment } from "models/breaker";
import { BaseEntity, FilterBy, PagedResult, PageRequest } from "models/common";
import { useCallback } from "react";
import { getPageRequest } from "util/helpers";
import { useCsvExport } from "../CsvExport";

export type BreakerHooks<T extends BaseEntity> = [
    // 1st element is the paged get request for the data under this breaker
    (pageRequest: PageRequest) => UseQueryResult<PagedResult<T>>,

    // 2nd element is the CSV export for the entire data set for this breaker, with any filters applied
    (filterBy?: FilterBy) => () => Promise<void>
];

interface BreakerHooksOptions {
    // upon a successful credit/debit by the breaker, the query cache for this data 
    // will be invalidated and re-fetched when set to true
    refreshOnNewTransaction?: boolean;

    // upon a successful customer data edit by the breaker, the query cache for this data 
    // will be invalidated and re-fetched when set to true
    refreshOnCustomerEdit?: boolean;

    // the guid id fields to leave in the CSV result, if any
    csvGuidFieldsToKeep?: string[];
}

export const createBreakerHooks = <T extends BaseEntity>(
    endpoint: string,
    {
        refreshOnNewTransaction,
        refreshOnCustomerEdit,
        csvGuidFieldsToKeep
    }: BreakerHooksOptions = {}
): BreakerHooks<T> => {
    const parts = endpoint.split('/').filter(Boolean);

    if (refreshOnNewTransaction) {
        parts.push(TransactionQueryKeySegment);
    }

    if (refreshOnCustomerEdit) {
        parts.push(CustomerQueryKeySegment);
    }

    const useDataHook = (pageRequest: PageRequest): UseQueryResult<PagedResult<T>> => {
        const request = getPageRequest(endpoint, pageRequest);

        const fetch = useAuthenticatedRequest<PagedResult<T>>(request);

        return useQuery([...parts, pageRequest], fetch);
    };

    const useCSVHook = (filterBy?: FilterBy) => {
        const request = getPageRequest(endpoint, { format: 'csv', filterBy });
        const csvExport = useCsvExport(request, parts.join('-'), csvGuidFieldsToKeep);

        // the function only really changes when the filter does, but react just
        // infinitely renders the thing, and I don't know how to do this the
        // right way, so here is me shutting esLint up when I specify an 'unused' dependency

        // eslint-disable-next-line 
        const memoizedCsvExport = useCallback(csvExport, [filterBy]);

        return memoizedCsvExport;
    };

    return [useDataHook, useCSVHook];
};

export const useBreakerDataRefresh = () => {
    const queryClient = useQueryClient();

    const invalidateQueriesWith = (key: string) => queryClient.invalidateQueries({
        predicate: q => q.queryKey.includes(key)
    });

    const onNewTransaction = async () =>
        invalidateQueriesWith(TransactionQueryKeySegment);

    const onCustomerEdit = async () =>
        invalidateQueriesWith(CustomerQueryKeySegment);

    return { onNewTransaction, onCustomerEdit };
};