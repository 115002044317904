import { LoadingOutlined } from "@ant-design/icons";

interface FullScreenSpinnerProps {
    visible?: boolean
}

const FullScreenSpinner = ({ visible = true }: FullScreenSpinnerProps) => {
    if (!visible) {
        return null;
    }

    return <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#000',
        opacity: '0.6',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 100
    }}>
        <LoadingOutlined spin style={{ color: 'var(--dcs-blue)', fontSize: '200px' }} />
    </div>
};

export default FullScreenSpinner;