import {
    useQuery
} from "@tanstack/react-query";
import { StaffSportsCard } from "models/cards";
import { PagedResult, PageRequest } from "models/common";
import { pageRequestToUrlSearchParams } from "util/helpers";
import { useAuthenticatedRequest } from "./useRequests";

export const StaffCardsQueryKey = "staff-cards";

const makeAxiosRequest = (pageRequest: PageRequest) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    return {
        method: "get",
        url: `/cards/all?${options}`,
    };
};

export const useStaffCardsCSV = (pageRequest: Omit<PageRequest, "page">) => {
    const pageRequestWithCSV = {
        ...pageRequest,
        page: 1, // ignored
        format: "csv",
    } as const;
    const axiosRequest = makeAxiosRequest(pageRequestWithCSV);

    return useAuthenticatedRequest<string>(axiosRequest);
};

const useStaffCards = (pageRequest: PageRequest) => {
    const key = [StaffCardsQueryKey, pageRequest];

    const getCards = useAuthenticatedRequest<PagedResult<StaffSportsCard>>(
        makeAxiosRequest(pageRequest)
    );

    return useQuery(key, getCards);
};

export default useStaffCards;
