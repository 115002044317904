import { Badge } from "antd";
import { useApplicationUsers } from "hooks/useApplicationUsers";
import { ApplicationUser } from "models/applicationUsers";
import { forwardRef, Ref } from "react";
import EntitySelector, {
    EntitySelectorProps,
    EntitySelectorRef
} from "./EntitySelector";
import MultiValueDisplay from "./MultiValueDisplay";

interface CustomerSpecificProps {
    includeInactive?: boolean,
    showStatus?: boolean,
    roles?: string[]
}

const resultDisplay = (customer: ApplicationUser, showStatus?: boolean) => {
    return <MultiValueDisplay
        status={showStatus === true
            ? <Badge dot color={customer.active ? "green" : "red"} style={{ marginRight: '5px' }} />
            : undefined
        }
        title={customer.userName}
        subTitle={`${customer.firstName} ${customer.lastName} - ${customer.email}`}
    />;
};

const CustomerSelector = (
    props: EntitySelectorProps<ApplicationUser> & CustomerSpecificProps,
    ref: Ref<EntitySelectorRef>
) => {
    const { findUsers } = useApplicationUsers();
    return (
        <EntitySelector
            ref={ref}
            {...props}
            placeholder={props.placeholder ?? "First, last, username or email"}
            search={str => findUsers({ name: str, includeInactive: props.includeInactive, roles: props.roles })}
            resultDisplay={cust => resultDisplay(cust, props.showStatus)}
        />
    );
};

export default forwardRef<
    EntitySelectorRef,
    EntitySelectorProps<ApplicationUser> & CustomerSpecificProps
>(CustomerSelector);
