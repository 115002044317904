import { Box } from "@chakra-ui/react";
import { Button, Col, Row } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { FilterValue } from "antd/lib/table/interface";
import StatusFilter from "components/staff/Packages/StatusFilter";
import TypeFilter from "components/staff/Packages/TypeFilter";
import TableActions from "components/TableActions";
import { useIdentity } from "context/auth";
import usePackages, { usePackagesCSV } from "hooks/usePackages";
import { PageRequest } from "models/common";
import { CustomerPackage, PackageStatus } from "models/packages";
import { FC, useEffect, useState } from "react";
import downloadAsCSV from "util/downloadAsCSV";
import getDefaultPageRequest from "util/getDefaultPageRequest";
import { localizedDate } from "util/helpers";
import { createCustomFilter, packageTypeDisplay } from "util/table";

interface PackagesFilterDetail {
    packageTypeId?: string | undefined;
    status?: PackageStatus | undefined;
}

const CustomerPackages: FC = () => {
    const { accounts } = useIdentity();

    const [{ page, filterBy, withinDays, sort }, setPageRequest] =
        useState<PageRequest>(getDefaultPageRequest());

    const { isLoading, data } = usePackages(accounts[0], {
        page,
        filterBy,
        withinDays,
        sort,
    });
    const [filterDetail, setFilterDetail] = useState<PackagesFilterDetail>({});
    const { data: records, totalRecords, pageSize } = data || {};

    const daysChanged = (value: number) => {
        setPageRequest({ page: 1, filterBy, withinDays: value, sort });
    };

    const tableChanged = (
        // pagination
        { current: newPage }: any,
        // filtering
        filters: Record<string, FilterValue | null>,
        // sorting
        { column, field, order }: any
    ) => {
        setPageRequest({
            page: newPage,
            filterBy: filters,
            withinDays,
            sort: column ? [field, order === "ascend"] : null,
        });
    };

    const onFilterUpdate = (key: string) => (value: any) => {
        setFilterDetail((prev) => ({ ...prev, [key]: value }));
    };

    const columns: ColumnsType<CustomerPackage> = [
        {
            title: "Package ID",
            dataIndex: "friendlyId",
            key: "friendlyId",
            sorter: true
        },
        {
            title: "Type",
            dataIndex: "packageType",
            key: "packageTypeId",
            ...createCustomFilter(
                TypeFilter,
                onFilterUpdate("packageTypeId"),
                filterDetail.packageTypeId
            ),
            render: (_, rec) => packageTypeDisplay(rec),
        },
        {
            title: "Status",
            dataIndex: "packageStatus",
            ...createCustomFilter(
                StatusFilter,
                onFilterUpdate("status"),
                filterDetail.status
            ),
            render: (status: PackageStatus) => PackageStatus[status],
            responsive: ['xl']
        },
        {
            title: "Received Date",
            dataIndex: "receivedDate",
            key: "receivedDate",
            sorter: true,
            render: localizedDate,
            responsive: ['md']
        },
        {
            title: "Est. Upload Date",
            dataIndex: "estimatedUploadDate",
            key: "estimatedUploadDate",
            sorter: true,
            render: localizedDate
        },
        {
            title: "Upload Date",
            dataIndex: "uploadDate",
            key: "uploadDate",
            sorter: true,
            render: localizedDate
        },
        {
            title: "",
            dataIndex: "ebayLink",
            key: "ebayLink",
            render: (link: string | null) =>
                link && (
                    <Button type="link" target="_blank" href={link}>
                        eBay Link
                    </Button>
                ),
            responsive: ['xl']
        },
    ];

    // HACK: After mounting, remove the query string from the URL
    // This is because we just don't have time to implement a filter for the uploadDate "filter",
    //  so when the user naturally refreshes we want it to work as expected
    useEffect(() => {
        const { search } = window.location;
        if (search) {
            window.history.replaceState({}, "", window.location.pathname);
        }
    }, []);

    const fetchCSV = usePackagesCSV(accounts[0], {
        filterBy,
        withinDays,
        sort,
    });

    const handleCSVExport = async () => {
        const csvData = await fetchCSV();
        const date = new Date().toISOString().split("T")[0];
        const filename = `packages-${date}.csv`;
        downloadAsCSV(csvData, filename);
    };

    return (
        <Box p={30}>
            <Row align="bottom">
                <Col span={6}>
                    <Box fontSize={36}>Packages</Box>
                </Col>
                <Col span={10} offset={8} className="actions-container">
                    <TableActions
                        onDaysChanged={daysChanged}
                        isLoading={isLoading}
                        showFilter={false}
                        onExportCSV={handleCSVExport}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={records}
                        rowKey={(r) => r.id}
                        onChange={tableChanged}
                        pagination={{
                            total: totalRecords,
                            current: page,
                            pageSize,
                            showSizeChanger: false,
                            showQuickJumper: true,
                        }}
                        loading={isLoading}
                    />
                </Col>
            </Row>
        </Box>
    );
};

export default CustomerPackages;
