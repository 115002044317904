import { Box, Stack } from "@chakra-ui/react";
import BasicLoadingSpinner from "components/BasicLoadingSpinner";
import {
    CreatePayoutRequestPayload,
    PayoutRequestMethod,
    ValidPaymentMethods,
} from "models/payoutRequests";
import { FC, Suspense } from "react";

import ACHConfirmation from "./ACHConfirmation";
import AmountsSummary from "./AmountsSummary";
import CheckConfirmation from "./CheckConfirmation";
import InternationalWireConfirmation from "./InternationalWireConfirmation";
import PaypalConfirmation from "./PaypalConfirmation";
import WireConfirmation from "./WireConfirmation";

export type ConfirmationDetailsProps = {
    payoutRequest: CreatePayoutRequestPayload | undefined;
};

const PayoutDetailsComponentMap: Record<
    ValidPaymentMethods,
    FC<ConfirmationDetailsProps>
> = {
    [PayoutRequestMethod.ACH]: ACHConfirmation,
    [PayoutRequestMethod.Check]: CheckConfirmation,
    [PayoutRequestMethod.Paypal]: PaypalConfirmation,
    [PayoutRequestMethod.Wire]: WireConfirmation,
    [PayoutRequestMethod.InternationalWire]: InternationalWireConfirmation
};

const RequestConfirmationDetails: FC<ConfirmationDetailsProps> = ({
    payoutRequest
}) => {
    const method = payoutRequest?.method;
    return (
        <Stack
            direction={{ base: "column", md: "row" }}
            minH="10rem"
            align="left"
            w="100%"
            justifyContent="space-between"
            spacing={5}
        >
            <Box>
                <Box
                    // Just like the PayoutMethodDetails component, we use the method as a key to
                    //  get the correct component to render
                    as={method && PayoutDetailsComponentMap[method]}
                    payoutRequest={payoutRequest}
                />
            </Box>
            {/* Suspense required because of the useAccountSummary hook being told to suspend */}
            <Suspense fallback={<BasicLoadingSpinner h="10rem" w="80%" />}>
                <AmountsSummary payoutRequest={payoutRequest} />
            </Suspense>
        </Stack>
    );
};

export default RequestConfirmationDetails;
