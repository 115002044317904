import FilterTag from "components/FilterTag";
import { CustomerAccount } from "models/accountSummary";
import { ApplicationUser } from "models/applicationUsers";
import { CardBatch } from "models/cards";
import { ClearTableFilterRequest } from "models/common";
import { customerAccountDisplay, localizedDate } from "util/helpers";

export interface StaffCardFilterDetail {
    batch?: CardBatch | undefined;
    status?: string | undefined;
    title?: string | undefined;
    customer?: ApplicationUser | undefined;
    friendlyPackageId?: string | undefined;
    account?: CustomerAccount | undefined;
}

const StaffCardsFilter = (props: { detail: StaffCardFilterDetail }) => {
    const { batch, status, title, account, friendlyPackageId } = props.detail;

    return (
        <div id="staff-card-active-filters">
            {batch && (
                <FilterTag
                    filter={ClearTableFilterRequest.Batch}
                    text={`Batch: ${batch.number} (${localizedDate(
                        batch.createdDate
                    )})`}
                />
            )}
            {friendlyPackageId && (
                <FilterTag
                    filter={ClearTableFilterRequest.FriendlyId}
                    text={`Package: ${friendlyPackageId}`}
                />
            )}
            {status && (
                <FilterTag
                    filter={ClearTableFilterRequest.Status}
                    text={`Status: ${status}`}
                />
            )}
            {title && (
                <FilterTag
                    filter={ClearTableFilterRequest.Title}
                    text={`Card title contains '${title}'`}
                />
            )}
            {account && (
                <FilterTag
                    filter={ClearTableFilterRequest.Customer}
                    text={`Customer: ${customerAccountDisplay(account)}`}
                />
            )}
        </div>
    );
};

export default StaffCardsFilter;
