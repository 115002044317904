import { Text } from "@chakra-ui/react";
import { Alert, Modal } from "antd";
import MultiValueDisplay from "components/MultiValueDisplay";
import useShipCardToCustomer from "hooks/useShipCardToCustomer";
import { CardStatus, StaffSportsCard } from "models/cards";
import { useState } from "react";
import { centsToDollars } from "util/helpers";
import { CardUpdateCallback } from "./EditCardModal";

const ShippedToCustomerModal = (props: {
    open: boolean,
    onClose: () => void,
    card: StaffSportsCard,
    onSuccess: CardUpdateCallback
}) => {
    const [working, setWorking] = useState<boolean>(false);
    const { shipCard } = useShipCardToCustomer();
    const { open, card } = props;

    const isPaid = card.status === CardStatus.Paid;

    const shipToCustomer = async () => {
        try {
            setWorking(true);
            await shipCard(card.cardId);
            await props.onSuccess(card.cardId);
            props.onClose();
        } finally {
            setWorking(false);
        }
    }

    return <Modal
        title={<MultiValueDisplay title="Ship This Card Back to the Customer" subTitle={card.title} />}
        open={open}
        closable={false}
        keyboard={false}
        maskClosable={false}
        confirmLoading={working}
        cancelButtonProps={{ disabled: working }}
        okText={working ? "Processing..." : "OK"}
        onOk={shipToCustomer}
        onCancel={props.onClose}
        destroyOnClose
    >
        <Text mb={5}>
            Flags this card as having been shipped back to the customer who sent it in. Cards in this status
            will ineligible for automatic re-listings, and cannot be changed to a different status.
        </Text>
        {isPaid && <Text mb={5}>
            The customer will have the sale price of {centsToDollars(card.salePriceInCents)} removed from their account.
        </Text>}
        {card.sku && <Text mb={5}>
            SKU: {card.sku}
        </Text>}
        <Alert
            showIcon
            type="warning"
            message="Are you sure?"
            description="Once it's been marked as shipped to the customer, there's no going back!"
        />
    </Modal>
};

export default ShippedToCustomerModal;