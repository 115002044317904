import { Dropdown, Menu } from "antd";
import { CardStatus, StaffSportsCard } from "models/cards";
import { useState } from "react";
import EditCardModal, { CardUpdateCallback } from "./EditCardModal";
import RelistModal from "./RelistModal";
import ReturnCardModal from "./ReturnCardModal";
import ShippedToCustomerModal from "./ShippedToCustomerModal";

const canRelist = (card: StaffSportsCard) => {
    return card.status === CardStatus.Active ||
        card.status === CardStatus.Cancelled ||
        card.status === CardStatus.Unsold;
}

const CardActions = (props: {
    card: StaffSportsCard,
    onUpdate: CardUpdateCallback
}) => {
    const [editOpen, setEditOpen] = useState(false);
    const [returnOpen, setReturnOpen] = useState(false);
    const [shippedOpen, setShippedOpen] = useState(false);
    const [relistOpen, setRelistOpen] = useState(false);

    const returned = props.card.status === CardStatus.Returned;
    const shippedBack = props.card.status === CardStatus.ShippedToCustomer;

    const silentUserButNoLinkedCustomer =
        props.card.silentUser !== null && props.card.silentUser!.linkedCustomerId === null;

    return <>
        <Dropdown.Button
            onClick={() => setEditOpen(true)}
            overlay={(() => (
                <Menu items={[
                    {
                        key: "return",
                        label: "Mark as Returned",
                        disabled: returned || shippedBack || silentUserButNoLinkedCustomer,
                        onClick: () => setReturnOpen(true)
                    },
                    {
                        key: "shipped",
                        label: "Shipped to Customer",
                        disabled: shippedBack || silentUserButNoLinkedCustomer,
                        onClick: () => setShippedOpen(true)
                    },
                    {
                        key: "relist",
                        label: "Relist",
                        disabled: !canRelist(props.card),
                        onClick: () => setRelistOpen(true)
                    }
                ]} />
            ))}
        >
            Edit
        </Dropdown.Button>
        <EditCardModal
            open={editOpen}
            onClose={() => setEditOpen(false)}
            card={props.card}
            onUpdate={props.onUpdate}
        />
        <ReturnCardModal
            open={returnOpen}
            onClose={() => setReturnOpen(false)}
            card={props.card}
            onSuccess={props.onUpdate}
        />
        <ShippedToCustomerModal
            open={shippedOpen}
            onClose={() => setShippedOpen(false)}
            card={props.card}
            onSuccess={props.onUpdate}
        />
        <RelistModal
            open={relistOpen}
            onClose={() => setRelistOpen(false)}
            card={props.card}
            onSuccess={props.onUpdate}
        />
    </>;
}

export default CardActions;