import { LinkOutlined, LoadingOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Button, Card, Col, Empty, Row, Space } from "antd";
import Currency from "components/Currency";
import BreakerCardTable from "components/customer/Breakers/BreakerCardTable";
import BreakerFundsTransferModal from "components/customer/Breakers/BreakerFundsTransferModal";
import BreakerLinkModal from "components/customer/Breakers/BreakerLinkModal";
import { useAuthenticatedRequest } from "hooks/useRequests";
import { CustomerBreaker } from "models/breaker";
import { useState } from "react";

type BreakerCardProps = CustomerBreaker & {
    onClick: (breaker: CustomerBreaker) => void,
    selected: boolean,
    onFundsTransfer: () => Promise<void>
};

const useCustomerBreakers = () => {
    const getBreakers = useAuthenticatedRequest<CustomerBreaker[]>({
        method: "get",
        url: "/customer/breakers"
    });

    return useQuery(["customer", "breakers"], getBreakers);
};

const BreakerCard = ({
    id,
    userName,
    email,
    creditBalanceInCents,
    awaitingPaymentsInCents,
    activeListingsInCents,
    ebayLink,
    onClick,
    selected,
    onFundsTransfer
}: BreakerCardProps) => {
    const activeListingNode = <Space direction="vertical">
        <span>Active Listings {ebayLink && <LinkOutlined />}</span>
        <Currency cents={activeListingsInCents} flat showZero color={activeListingsInCents > 0 ? 'var(--dcs-blue)' : 'inherit'} />
    </Space>;

    const activeListingAction = (ebayLink && (<a href={ebayLink} target="_blank" rel="noreferrer">
        {activeListingNode}
    </a>)) || activeListingNode;

    const breakerSelected = () => {
        onClick({ id, userName, email, creditBalanceInCents, awaitingPaymentsInCents, activeListingsInCents, ebayLink });
    }

    return <Col xxl={6} lg={12} xs={24}>
        <Card
            actions={[
                <Space direction="vertical">
                    <span>Credit Balance</span>
                    <Currency cents={creditBalanceInCents} showZero />
                </Space>,
                <Space direction="vertical">
                    <span>Awaiting Payment</span>
                    <Currency cents={awaitingPaymentsInCents} flat showZero />
                </Space>,
                activeListingAction
            ]}
            bodyStyle={{ padding: '12px' }}
            style={selected ? { backgroundColor: 'antiquewhite' } : undefined}
        >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap-reverse' }}>
                <div style={{ cursor: 'pointer', flex: 1 }} onClick={breakerSelected}>
                    <div style={{ fontSize: '26px' }}>{userName}</div>
                    <div style={{ fontSize: '16px', opacity: 0.3 }}>{email}</div>
                </div>
                <div>
                    <BreakerFundsTransferModal
                        breakerId={id}
                        breakerUserName={userName}
                        breakerCreditBalanceInCents={creditBalanceInCents}
                        onFundsTransfer={onFundsTransfer}
                    />
                </div>
            </div>
        </Card>
    </Col>;
}

const Breakers = () => {
    const { data = [], isLoading, refetch } = useCustomerBreakers();
    const [breaker, setBreaker] = useState<CustomerBreaker>();
    const [linkModalOpen, setLinkModalOpen] = useState(false);

    const onBreakerSelected = (breaker: CustomerBreaker) => {
        setBreaker(breaker);
    }

    const reloadBreakers = async () => {
        await refetch();
    }

    const openLinkModal = () => setLinkModalOpen(true);

    return <div style={{ padding: '30px' }}>
        <Row style={{ marginBottom: '30px' }}>
            <Col span={24}>
                <Space style={{ alignItems: 'baseline' }}>
                    <div style={{ fontSize: '36px' }}>Breakers You're Connected With</div>
                    <Button style={{ color: 'var(--dcs-white)' }} type="link" onClick={openLinkModal}>
                        <span style={{ textDecoration: 'underline' }}>Don't see a breaker you're working with?</span>
                    </Button>
                </Space>
                <BreakerLinkModal
                    open={linkModalOpen}
                    onClose={async () => setLinkModalOpen(false)}
                />
            </Col>
        </Row>

        <Row gutter={[16, 16]}>
            {isLoading &&
                <Col span={4}>
                    <LoadingOutlined spin style={{ color: 'var(--dcs-light-blue)', fontSize: '90px' }} />
                </Col>}

            {data.length === 0 && !isLoading &&
                <Col span={24}>
                    <Empty description="Looks like you're not connected with any breakers!">
                        <Button type="primary" onClick={openLinkModal}>Maybe we can fix that</Button>
                    </Empty>
                </Col>}

            {data.length > 0 && <>
                {data.map(b => <BreakerCard
                    {...b}
                    key={b.id}
                    selected={breaker?.id === b.id}
                    onClick={onBreakerSelected}
                    onFundsTransfer={reloadBreakers}
                />)}

                <Col span={24}>
                    <BreakerCardTable
                        key={breaker?.id ?? 'no-breaker'}
                        breaker={breaker}
                    />
                </Col>
            </>}
        </Row>
    </div>;
};

export default Breakers;