import { ArrowLeftOutlined, ArrowRightOutlined, AuditOutlined, FormOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Steps } from "antd";
import axios from 'axios';
import { BreakerCustomer } from "models/breaker";
import { AdjustmentType } from "models/common";
import { useEffect, useState } from "react";
import ConfirmationStep from "./ConfirmationStep";
import CustomerBanner from './CustomerBanner';
import DetailsStep from "./DetailsStep";
import ResultsStep, { TransactionSuccess } from './ResultsStep';
import useBreakerFundsTransfer from './useBreakerFundsTransfer';
const { Step } = Steps;

enum ModalSteps {
    Details = 0,
    Confirm = 1,
    Result = 2
}

export interface BreakerTransactionForm {
    amount: string;
    amountInCents: number;
    type: AdjustmentType;
    customer: BreakerCustomer;
    description: string;
}

const useButtonText = (step: ModalSteps, submitting: boolean, successful: boolean) => {
    const Next = <>Next <ArrowRightOutlined /></>;
    const Prev = <><ArrowLeftOutlined /> Prev</>;

    switch (step) {
        case ModalSteps.Details:
            return ["Cancel", Next];
        case ModalSteps.Confirm:
            return [Prev, submitting ? "Submitting..." : Next];
        case ModalSteps.Result:
            return [successful ? Prev : "Try Again", "Done"];
        default:
            return ["Cancel", "Ok"];
    }
}

const UseCreditModal = (props: { customer: BreakerCustomer }) => {
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm<BreakerTransactionForm>();
    const [step, setStep] = useState<ModalSteps>(ModalSteps.Details);
    const [fundsToCustomerInCents, setFundsToCustomerInCents] = useState(NaN);
    const { initiateTransfer, isLoading } = useBreakerFundsTransfer();
    const [result, setResult] = useState<number | undefined>();
    const successfulResult = result === TransactionSuccess;
    const [cancelText, okText] = useButtonText(step, isLoading, successfulResult);
    const { customer } = props;

    useEffect(() => {
        if (open) {
            form.setFieldsValue({ customer });
        }
    }, [open, customer, form]);

    const closeAndResetModal = () => {
        setOpen(false);
        setStep(ModalSteps.Details);
        setFundsToCustomerInCents(NaN);
        setResult(undefined);
        form.resetFields();
    };

    const submit = async () => {
        try {
            const data = form.getFieldsValue(true);
            await initiateTransfer({ ...data, amountInCents: fundsToCustomerInCents });
            setResult(TransactionSuccess);
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                setResult(error.response!.status);
            } else {
                setResult(0);
            }
        }
    }

    const nextStep = async () => {
        switch (step) {
            case ModalSteps.Details: {
                const data = await form.validateFields();
                setFundsToCustomerInCents(data.type === AdjustmentType.Credit
                    ? data.amountInCents
                    : -data.amountInCents);
                setStep(ModalSteps.Confirm);
                break;
            }
            case ModalSteps.Confirm:
                await submit();
                setStep(ModalSteps.Result);
                break;
            case ModalSteps.Result:
                closeAndResetModal();
                break;
        }
    };

    const previousStep = () => {
        switch (step) {
            case ModalSteps.Details:
                closeAndResetModal();
                break;
            case ModalSteps.Confirm:
                setStep(ModalSteps.Details);
                break;
            case ModalSteps.Result:
                if (!successfulResult) {
                    setStep(ModalSteps.Details);
                }
                break;
        }
    };

    return <>
        <Button onClick={() => setOpen(true)}>
            Use Credit
        </Button>
        <Modal
            className="manual-adjustment-modal"
            title="Initiate Credit / Debit"
            closable={false}
            keyboard={false}
            maskClosable={false}
            destroyOnClose
            open={open}
            onOk={nextStep}
            okText={okText}
            onCancel={previousStep}
            confirmLoading={isLoading}
            cancelText={cancelText}
            cancelButtonProps={{ disabled: isLoading || successfulResult }}
        >
            <Steps size="small" current={step}>
                <Step title="Details" icon={<FormOutlined />} />
                <Step title="Confirm" icon={<AuditOutlined />} />
                <Step title="Result" icon={<InfoCircleOutlined />} />
            </Steps>

            <CustomerBanner {...customer} />

            <DetailsStep
                form={form}
                active={step === ModalSteps.Details}
            />

            <ConfirmationStep
                toCustomerInCents={fundsToCustomerInCents}
                description={step !== ModalSteps.Confirm ? '' : form.getFieldValue('description')}
                active={step === ModalSteps.Confirm}
            />

            <ResultsStep
                toCustomerInCents={fundsToCustomerInCents}
                status={result}
                active={step === ModalSteps.Result}
            />
        </Modal>
    </>;
};

export default UseCreditModal;