import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";
import { useAuthenticatedRequest, useAuthenticatedRequestCreator } from "hooks/useRequests";
import { CardPhotoType } from "models/cardIngestion";
import { UploadRequestOption } from 'rc-upload/lib/interface';

interface LiveCardImages {
    frontImage: string | null;
    backImage: string | null;
    extraImages: string[];
}

interface RelistRequest {
    cardId: string;
    frontImageUrl: string | null;
    backImageUrl: string | null;
    extraImageUrls: string[];
    newTitle: string | null;
    scheduledDate: string | null;
}

interface CardImageUploadArgs {
    cardId: string;
    type: CardPhotoType;
    options: UploadRequestOption;
}

interface SuccessfulUpload {
    url: string;
    type: CardPhotoType;
}

export const useLiveCardImages = (cardId: string) => {
    const getLiveImages = useAuthenticatedRequest<LiveCardImages>({
        method: 'get',
        url: `/cards/${cardId}/live-images`
    });

    const { refetch, isLoading } = useQuery(["cards", cardId, "live-images"], getLiveImages, {
        enabled: false // forced to fetch images on demand, by way of the refetch wrapper
    });

    const fetchImages = async (): Promise<LiveCardImages> => {
        const { data } = await refetch();
        return data!;
    }

    return { fetchImages, isLoadingImages: isLoading };
}

export const useCardImageUpload = () => {
    const axiosRequestConfig = (model: CardImageUploadArgs): AxiosRequestConfig => {
        const formData = new FormData();
        formData.append("image", model.options.file);
        formData.append("type", model.type.toString());

        return {
            method: 'post',
            url: `/cards/${model.cardId}/image`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (event: ProgressEvent) => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                if (model.options.onProgress) {
                    model.options.onProgress({ percent });
                }
                if (percent === 100 && model.options.onSuccess) {
                    model.options.onSuccess("ok");
                }
            }
        }
    }

    const { mutateAsync: uploadImage, isLoading } = useMutation(
        useAuthenticatedRequestCreator<SuccessfulUpload, CardImageUploadArgs>(axiosRequestConfig, [400])
    );

    const { mutateAsync: removeImage } = useMutation(
        useAuthenticatedRequestCreator<void, string>((url) => ({
            method: 'delete',
            url: '/cards/image',
            data: { url }
        }))
    );

    return { uploadImage, removeImage, isUploading: isLoading };
};

export const useCardRelist = () => {
    const relist = useAuthenticatedRequestCreator<boolean, RelistRequest>((model) => ({
        method: 'post',
        url: `/cards/${model.cardId}/relist`,
        data: model
    }), [400]);

    const { mutateAsync: relistCard, isLoading } = useMutation(relist);

    return { relistCard, isRelisting: isLoading };
};