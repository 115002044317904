import { SaveFilled } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Button, Card, Form, FormInstance, Input, Popover } from "antd";
import axios from "axios";
import { useAuthenticatedRequestCreator } from "hooks/useRequests";
import { SilentUser } from "models/applicationUsers";
import { forwardRef, ReactNode, Ref, useState } from "react";
import EntitySelector, { EntitySelectorProps, EntitySelectorRef } from "./EntitySelector";
import MultiValueDisplay from "./MultiValueDisplay";

interface SilentUserSelectorProps {
    ownerId: string;
    allowUserCreation?: boolean;
    onCreating?: () => void;
    onCreated?: (newUser: SilentUser) => void;
    onError?: (message: string) => void;
}

interface NewUserDetailsForm {
    firstName: string,
    lastName: string,
    email: string
}

const useSilentUsers = () => {
    const findUsers = useAuthenticatedRequestCreator<SilentUser[], { ownerId: string, name: string }>(
        opt => {
            const { ownerId, name } = opt;

            return {
                method: "get",
                url: `/account/silent-users?ownerId=${ownerId}&name=${name}`
            };
        }
    );

    const newUser = useAuthenticatedRequestCreator<SilentUser, { breakerId: string, userName: string }>(
        data => ({
            method: "post",
            url: '/packages/new-silent-user',
            data
        }), [400]
    );

    const updateUser = useAuthenticatedRequestCreator<void, SilentUser>(
        (user) => ({
            method: "post",
            url: '/packages/update-silent-user',
            data: { ...user, silentUserId: user.id, breakerId: user.ownerId }
        })
    );

    const { mutateAsync: createSilentUser, isLoading: isCreating } = useMutation(newUser);
    const { mutateAsync: updateSilentUser, isLoading: isUpdating } = useMutation(updateUser);

    return { findUsers, createSilentUser, updateSilentUser, isSaving: isCreating || isUpdating };
}

const resultDisplay = (user: SilentUser) => {
    return <MultiValueDisplay
        title={user.userName}
        subTitle={`${user.firstName} ${user.lastName} - ${user.email}`}
    />
};

const coalese = (a: string, b: string) => a?.trim() === '' ? b : a ?? b;

const NewUserDetails = (props: {
    form: FormInstance<NewUserDetailsForm>,
    newUser: SilentUser
    onUpdate?: (user: SilentUser) => Promise<void>;
}) => {
    const {
        form,
        newUser,
        onUpdate = () => { },
    } = props;

    return <Card
        bodyStyle={{ padding: '0' }}
        actions={[
            <Button
                block
                icon={<SaveFilled />}
                type="primary"
                onClick={async () => {
                    const changes = form.getFieldsValue();
                    await onUpdate({
                        ...newUser,
                        firstName: coalese(changes.firstName, newUser.firstName),
                        lastName: coalese(changes.lastName, newUser.lastName),
                        email: coalese(changes.email, newUser.email)
                    });
                }}>
                Save Changes
            </Button>
        ]}
        bordered={false}
        type="inner">
        <Form form={form}>
            <Form.Item label="First Name" name="firstName">
                <Input placeholder={newUser.firstName} />
            </Form.Item>
            <Form.Item label="Last Name" name="lastName">
                <Input placeholder={newUser.lastName} />
            </Form.Item>
            <Form.Item label="Email" name="email">
                <Input placeholder={newUser.email} />
            </Form.Item>
        </Form>
    </Card>
}

const SilentUserSelector = (
    props: EntitySelectorProps<SilentUser> & SilentUserSelectorProps,
    ref: Ref<EntitySelectorRef>
) => {
    const {
        placeholder, ownerId, allowUserCreation, disabled,
        onCreating, onCreated, onError, onSelected, onClear
    } = props;
    const [userName, setUserName] = useState<string>();
    const { findUsers, createSilentUser, updateSilentUser, isSaving } = useSilentUsers();
    const [newUserDetails, setNewUserDetails] = useState<ReactNode | null>(null);
    const [form] = Form.useForm<NewUserDetailsForm>();

    const create = async () => {
        try {
            onCreating && onCreating();

            const newUser = await createSilentUser({
                breakerId: ownerId,
                userName: userName!
            });

            onSelected(newUser);
            onCreated && onCreated(newUser);
            setNewUserDetails(NewUserDetails({ form, newUser, onUpdate: newUserUpdated }));
        } catch (error) {
            if (axios.isAxiosError(error) && onError) {
                const errMessage = error.response!.data as any;
                onError(errMessage.title);
            }
        }
    };

    const newUserUpdated = async (user: SilentUser) => {
        await updateSilentUser(user);
        setNewUserDetails(null);
        onSelected(user);
        form.resetFields();
    }

    const AddBreaksUser = <Button type="primary" block size="large" onClick={create} disabled={isSaving}>
        Add Breaks User
    </Button>;

    return (
        <Popover open={!!newUserDetails} content={newUserDetails} placement="bottomLeft">
            <EntitySelector
                ref={ref}
                {...props}
                onClear={() => {
                    setNewUserDetails(null);
                    onClear && onClear();
                }}
                disabled={isSaving || disabled}
                onInputChange={value => {
                    setUserName(value);
                    onError && onError(null!);
                    setNewUserDetails(null);
                }}
                placeholder={isSaving
                    ? "Creating New User..."
                    : placeholder ?? "First, last, username or email"}
                notFoundContent={allowUserCreation
                    ? AddBreaksUser
                    : null}
                search={name => findUsers({ ownerId, name })}
                resultDisplay={resultDisplay}
                postAutoComplete={<div style={{ margin: '12px 12px 7px 12px' }}>
                    {AddBreaksUser}
                </div>}
            />
        </Popover>
    );
};

export default forwardRef<
    EntitySelectorRef,
    EntitySelectorProps<SilentUser> & SilentUserSelectorProps
>(SilentUserSelector);