import { useMutation } from "@tanstack/react-query";
import { NewPackageFormData } from "components/staff/Packages/NewPackageForm";
import { StaffPackage } from "models/packages";
import { useAuthenticatedRequestCreator } from "./useRequests";

const useCreatePackage = () => {
    const createPackageCreator = useAuthenticatedRequestCreator<StaffPackage, NewPackageFormData>(formData => ({
        method: "post",
        url: "/packages/new",
        data: {
            customerId: formData.customer.id,
            packageTypeId: formData.packageType.id,
            amount: formData.amount,
            accountId: formData.silentUser?.accountId
        }
    }));

    const { mutateAsync: createPackage, isLoading: working } = useMutation(
        (formData: NewPackageFormData) => createPackageCreator(formData)
    );

    return { createPackage, working };
};

export default useCreatePackage;
