import { LinkOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { BreakerCustomerSearch } from "components/EntityTableSearch";
import MultiValueDisplay from "components/MultiValueDisplay";
import { BreakerCustomer, BreakerCustomerSearchResult } from "models/breaker";
import { useState } from "react";
import { centsToDollars } from "util/helpers";
import { createCustomFilter } from "util/table";
import BreakerTable from "./BreakerTable";
import { createBreakerHooks } from "./createBreakerHooks";
import EditCustomerModal from "./EditCustomerModal";
import UseCreditModal from "./UseCreditModal/UseCreditModal";

const hooks = createBreakerHooks<BreakerCustomer>('/breaker/users', {
    refreshOnNewTransaction: true,
    refreshOnCustomerEdit: true
});

const BreakerCustomers = () => {
    const [filter, setFilter] = useState<{
        customer?: BreakerCustomerSearchResult;
    }>({});

    const columns: ColumnsType<BreakerCustomer> = [
        {
            dataIndex: "linkedToConsignmentCustomer",
            align: 'center',
            render: (linked: boolean) => linked
                ? <LinkOutlined title="Linked to a dcsports87 consignment customer" />
                : null
        },
        {
            title: <MultiValueDisplay title="User Name" subTitle="Email" titleColor="inherit" subTitleColor="inherit" />,
            dataIndex: "userName",
            key: "id",
            sorter: true,
            ...createCustomFilter(
                BreakerCustomerSearch,
                (customer) => setFilter({ customer }),
                filter.customer?.id
            ),
            render: (userName, rec) => (
                <MultiValueDisplay
                    title={userName}
                    subTitle={rec.email}
                />
            ),
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            sorter: true
        },
        {
            title: "Last Name",
            dataIndex: "lastName",
            sorter: true
        },
        {
            title: "Credit Balance",
            dataIndex: "creditBalanceInCents",
            sorter: true,
            render: (v) => centsToDollars(v)
        },
        {
            title: "Awaiting Payment",
            dataIndex: "awaitingPaymentsInCents",
            render: (v) => centsToDollars(v)
        },
        {
            title: "Active Listings",
            dataIndex: "activeListingsInCents",
            render: (v) => centsToDollars(v)
        },
        {
            title: "eBay Link",
            dataIndex: "ebayLink",
            render: (link: string | null) =>
                link && (<Button type="link" target="_blank" href={link}>Active Listings</Button>)
        },
        {
            title: "Actions",
            key: "edit",
            align: 'center',
            render: (_, rec) => <Space>
                <UseCreditModal customer={rec} />
                <EditCustomerModal customer={rec} />
            </Space>
        }
    ];

    return <BreakerTable
        hooks={hooks}
        columns={columns}
        defaultPageSize={50}
        noDataText="You don't have any customers!"
    />;
}

export default BreakerCustomers;