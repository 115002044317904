import { LoadingOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Empty, Image, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue } from "antd/lib/table/interface";
import CsvExport, { useCsvExport } from "components/CsvExport";
import Currency from "components/Currency";
import MultiValueDisplay from "components/MultiValueDisplay";
import StatusFilter from "components/staff/Cards/StatusFilter";
import { useAuthenticatedRequest } from "hooks/useRequests";
import { CustomerBreaker, CustomerBreakerCard } from "models/breaker";
import { CardStatus } from "models/cards";
import { PagedResult, PageRequest } from "models/common";
import { useMemo, useState } from "react";
import { DefaultPageRequest } from "util/getDefaultPageRequest";
import { getPageRequest, localizedDate } from "util/helpers";
import { createCustomFilter } from "util/table";

interface BreakerCardTableProps {
    breaker?: CustomerBreaker
}

const useColumns = () => {
    const [filter, setFilter] = useState<{
        status?: CardStatus
    }>({});

    const columns: ColumnsType<CustomerBreakerCard> = [
        {
            title: "Breaker",
            dataIndex: "breakerUserId",
            sorter: false,
            render: (_, rec) => <MultiValueDisplay
                title={rec.breakerUserName}
                subTitle={rec.breakerEmail} />,
            responsive: ['lg']
        },
        {
            title: "Status",
            dataIndex: "status",
            ...createCustomFilter(
                StatusFilter,
                status => setFilter({ status }),
                filter.status
            ),
        },
        {
            title: "Image",
            dataIndex: "frontImageUrl",
            render: (url) => <Image
                src={url}
                width={30}
                placeholder={<LoadingOutlined />} />,
            responsive: ['xl']
        },
        {
            title: "Name",
            dataIndex: "title",
            sorter: true,
        },
        {
            title: "Listing Date",
            dataIndex: "listingDate",
            sorter: true,
            render: localizedDate,
            responsive: ['xl']
        },
        {
            title: "Sale Price",
            dataIndex: "salePriceInCents",
            key: "salePrice",
            sorter: true,
            align: "right",
            render: (a) => <Currency cents={a} />,
            responsive: ['lg']
        },
        {
            title: "Fees",
            dataIndex: "feesInCents",
            key: "fees",
            align: "right",
            render: (a) => <Currency cents={-a} />,
            responsive: ['lg']
        },
        {
            title: "Net Amount",
            dataIndex: "netInCents",
            key: "net",
            align: "right",
            render: (a) => <Currency cents={a} />,
        },
    ];

    return columns;
}

const useBreakerCardsForCustomer = (breaker: CustomerBreaker | undefined, pageRequest: PageRequest) => {
    const url = `/customer/breakers/${breaker?.id}/cards`;

    const request = getPageRequest(url, pageRequest);
    const fetchCards = useAuthenticatedRequest<PagedResult<CustomerBreakerCard>>(request);

    const csvRequest = useMemo(() => {
        return getPageRequest(url, { format: 'csv', filterBy: pageRequest.filterBy });
    }, [url, pageRequest]);
    const csvDownload = useCsvExport(csvRequest, `breaker-cards-${breaker?.userName}`);

    return {
        ...useQuery(["customer", "breaker", breaker?.id, "cards", pageRequest], fetchCards, {
            enabled: !!breaker?.id
        }),
        csvDownload
    };
}

const BreakerCardTable = ({ breaker }: BreakerCardTableProps) => {
    const [{ page, filter, filterBy, withinDays, sort }, setPageRequest] =
        useState<PageRequest>(DefaultPageRequest);

    const columns = useColumns();

    const {
        data = {
            data: [],
            totalRecords: 0,
            pageSize: 0
        },
        isLoading,
        csvDownload
    } = useBreakerCardsForCustomer(breaker, {
        page,
        filter,
        filterBy,
        withinDays,
        sort,
    });

    const { data: records, totalRecords, pageSize } = data;

    const tableChanged = (
        { current: newPage }: any,
        filters: Record<string, FilterValue | null>,
        { column, field, order }: any
    ) => {
        setPageRequest({
            page: newPage,
            filter,
            filterBy: filters,
            withinDays,
            sort: column ? [field, order === "ascend"] : null,
        });
    };

    return <Table
        loading={breaker !== undefined && isLoading}
        columns={columns}
        dataSource={records}
        rowKey={r => r.cardId}
        onChange={tableChanged}
        showSorterTooltip={false}
        pagination={{
            total: totalRecords,
            current: page,
            pageSize,
            showSizeChanger: false,
            showQuickJumper: true,
            position: ['topRight'],
            showTotal: () => <CsvExport onExport={csvDownload} buttonStyle={{ color: 'var(--dcs-white)' }} />
        }}
        locale={{
            emptyText: <Empty description={breaker ?
                `No cards found under ${breaker.userName}!` :
                "Click on a breaker to display the cards they've listed for you"} />
        }}
    />
};

export default BreakerCardTable;