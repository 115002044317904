import { SilentUser } from "./applicationUsers";

/**
 * A value representing the status of a card based on the shape of the card data
 */
export enum CardStatus {
    /**
     * Currently listed on eBay
     */
    Active = "Active",
    /**
     * The card auction has ended and the buyer has paid for it
     */
    Paid = "Paid",
    /**
     * The card auction has ended, but the card buyer has not paid for it yet
     */
    AwaitingPayment = "AwaitingPayment",
    Unsold = "Unsold",
    Cancelled = "ListingEnded",
    /**
     * Card was sent to eBay for verification but had undisclosed flaws and
     * was returned to dcsports87
     */
    Returned = "Returned",
    /**
     * Card was shipped back to the customer who sent it in
     */
    ShippedToCustomer = "ShippedToCustomer"
}

/**
 * A card that has been submitted to dcsports87 by a customer, being viewed by a customer
 */
export type SportsCard = {
    cardId: string;
    title: string;
    status: CardStatus;
    listingDate: string;
    salePriceInCents: number;
    feesInCents: number;
    netInCents: number;
    frontImageUrl: string | null;
};

/**
 * A card for a customer that is being viewed by staff
 */
export type StaffSportsCard = {
    cardId: string;
    title: string;
    status: CardStatus;
    listingDate: string;
    salePriceInCents: number;
    accountId: string;
    batchId: string;
    batchNumber: string;
    batchCreatedDate: string;
    customerId: string;
    customerFirstName: string | undefined;
    customerLastName: string | undefined;
    customerEmail: string;
    customerUserName: string;
    friendlyPackageId: number;
    sku: string;
    order: StaffSportsCardOrder | undefined;
    ebayItemId: string;
    silentUser: SilentUser | null;
};

export interface StaffSportsCardOrder {
    id: string; // the eBay order number
    creationDate: Date;
    paymentDate: Date | undefined;
    buyerName: string;
    buyerEmail: string;
    buyerUsername: string;
    address1: string | undefined;
    address2: string | undefined;
    city: string | undefined;
    state: string | undefined;
    zipCode: string | undefined;
    lineItems: StaffSportsCardOrderLineItem[];
}

export interface StaffSportsCardOrderLineItem {
    id: string; // the eBay order line item ID
    legacyId: string; // the eBay item number
    rawSKU: string;
    title: string;
    priceInCents: number;
    orderCreationDate: Date;
    shippedDate: Date;
}

/**
 * Represents the batch that any given card must belong to
 */
export type CardBatch = {
    id: string;
    number: string;
    createdDate: string;
};
