import { Badge } from "antd";
import { useAuthenticatedRequestCreator } from "hooks/useRequests";
import { AccountType, CustomerAccount } from "models/accountSummary";
import { forwardRef, Ref } from "react";
import EntitySelector, { EntitySelectorProps, EntitySelectorRef } from "./EntitySelector";
import MultiValueDisplay from "./MultiValueDisplay";

interface AccountSelectorProps { }

const useAccountSearch = () => {
    const search = useAuthenticatedRequestCreator<CustomerAccount[], { term: string }>(
        data => ({
            method: "get",
            url: `/customer-account/search?term=${data.term}`
        })
    );

    return { search };
}

const SilentAccountDisplay = ({ firstName, lastName, email, userName, ownerUserName }: CustomerAccount) =>
    <MultiValueDisplay
        status={<Badge dot color="#d9d9d9" style={{ marginRight: '5px' }} />}
        title={`${userName} via ${ownerUserName}`}
        subTitle={`${firstName} ${lastName} - ${email}`}
    />;

const ConsignmentAccountDisplay = ({ firstName, lastName, userName, email, active }: CustomerAccount) =>
    <MultiValueDisplay
        status={<Badge dot color={active ? "green" : "red"} style={{ marginRight: '5px' }} />}
        title={userName}
        subTitle={`${firstName} ${lastName} - ${email}`}
    />;

const resultDisplay = (account: CustomerAccount) => {

    if (account.type === AccountType.Consignment) {
        return <ConsignmentAccountDisplay {...account} />;
    }

    return <SilentAccountDisplay {...account} />;
};

const AccountSelector = (
    props: EntitySelectorProps<CustomerAccount> & AccountSelectorProps,
    ref: Ref<EntitySelectorRef>
) => {
    const { search } = useAccountSearch();

    return <EntitySelector
        ref={ref}
        {...props}
        placeholder={props.placeholder ?? "First, last, username or email"}
        search={term => search({ term })}
        resultDisplay={resultDisplay}
    />
};

export default forwardRef<
    EntitySelectorRef,
    EntitySelectorProps<CustomerAccount> & AccountSelectorProps
>(AccountSelector);