import { Box, Flex, VStack } from "@chakra-ui/react";
import { Skeleton } from "antd";
import { Link } from "react-router-dom";

interface CustomerInfoBoxProps {
    title: string;
    subTitle: string;
    focusText: string | undefined;
    linkText?: string;
    linkUrl?: string;
    isLinkExternal?: boolean;
    isLoading: boolean;
    forceReload?: boolean;
    onLinkClick?: () => void;
}

export const CustomerInfoBox = ({
    title,
    subTitle,
    focusText,
    linkUrl,
    linkText,
    isLinkExternal,
    isLoading,
    forceReload,
    onLinkClick
}: CustomerInfoBoxProps) => {
    const hasLink = linkUrl && linkText;

    return (
        <Flex
            flexDir="column"
            justifyContent="space-between"
            w="100%"
            px="2em"
            pt="0.5em"
            pb="1em"
            bg="white"
            rounded="lg"
            align="start"
            flex={1}
            minH="12rem"
        >
            <VStack align="start" spacing={0}>
                <Box fontSize={28} fontWeight="semibold">
                    {title}
                </Box>
                <Box fontSize={14} opacity={0.5}>
                    {subTitle}
                </Box>
            </VStack>
            <VStack align="start" spacing={0} w="100%">
                <Box
                    w="100%"
                    fontSize={36}
                    fontWeight="semibold"
                    borderBottom="3px solid"
                    borderColor="var(--dcs-light-blue)"
                >
                    {isLoading ? <Skeleton.Input active /> : focusText}
                </Box>
                <Box color="blue" fontSize={16} textDecor="underline" pt={2}>
                    {hasLink && !isLinkExternal && (
                        <Link to={linkUrl} onClick={forceReload ? window.location.reload : undefined}>{linkText}</Link>
                    )}
                    {hasLink && isLinkExternal && (
                        <Box
                            as="a"
                            href={linkUrl}
                            target="_blank"
                            rel="noreferrer"
                            color="blue"
                        >
                            {linkText}
                        </Box>
                    )}
                    {!hasLink && onLinkClick && (
                        <Box
                            as="a"
                            onClick={onLinkClick}
                            rel="noreferrer"
                            color="blue"
                        >
                            {linkText}
                        </Box>
                    )}
                    {!hasLink && !onLinkClick && (
                        <div style={{ visibility: "hidden" }}>&nbsp;</div>
                    )}
                </Box>
            </VStack>
        </Flex>
    );
};
