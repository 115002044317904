import { FileTextOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { AxiosRequestConfig } from "axios";
import { useAuthenticatedRequest } from "hooks/useRequests";
import { CSSProperties, FC, useState } from "react";
import downloadAsCSV from "util/downloadAsCSV";

export const useCsvExport = (
    request: AxiosRequestConfig,
    filenamePrefix: string,
    guidFieldsToKeep?: string[]
) => {
    const download = useAuthenticatedRequest<string>(request);

    return async () => {
        const csvData = await download();
        const date = new Date().toISOString().split("T")[0];
        const filename = `${filenamePrefix}-${date}.csv`;

        downloadAsCSV(csvData, filename, guidFieldsToKeep);
    }
}

const CsvExport: FC<{
    onExport: () => Promise<void>,
    disabled?: boolean,
    buttonStyle?: CSSProperties
}> = ({ onExport, disabled, buttonStyle }) => {
    const [fetching, setFetching] = useState(false);

    const exportCsv = async () => {
        setFetching(true);
        await onExport();
        setFetching(false);
    };

    const icon = fetching ? <LoadingOutlined /> : <FileTextOutlined />;

    return <Row justify="end">
        <Col span={4} style={{ display: 'contents' }}>
            <Button type="link" disabled={fetching || disabled} icon={icon} onClick={exportCsv} style={buttonStyle}>
                Export to CSV
            </Button>
        </Col>
    </Row>;
}

export default CsvExport;