import { Box } from "@chakra-ui/react";
import { Alert, Checkbox, Col, Form, Input, Modal, Radio, Row, Select } from "antd";
import { RuleObject } from "antd/lib/form";
import CustomerSelector from "components/CustomerSelector";
import SilentUserSelector from "components/SilentUserSelector";
import { Roles } from "context/auth";
import useAvailablePackageTypes from "hooks/useAvailablePackageTypes";
import usePackageUpdate from "hooks/usePackageUpdate";
import { AccountType } from "models/accountSummary";
import { ApplicationUser, SilentUser } from "models/applicationUsers";
import { PackageTypeOption } from "models/common";
import { PackageStatus, StaffPackage } from "models/packages";
import { useEffect, useState } from "react";
import { centsToDollars, dollarsToCents } from "util/helpers";

interface EditPackageForm {
    customer: ApplicationUser | null | undefined;
    silentUser: SilentUser | null | undefined;
    packageTypeId: string;
    status: PackageStatus;
    processingFeeInCents: number | null;
    processingFee: string | null;
    priority: boolean;
    sendProcessingFee?: boolean;
}

const EditPackageModal = (props: {
    open: boolean;
    package: StaffPackage;
    onClose: (edited?: boolean) => void;
}) => {
    const { updatePackage } = usePackageUpdate();
    const [working, setWorking] = useState<boolean>(false);
    const [form] = Form.useForm<EditPackageForm>();
    const { data: types } = useAvailablePackageTypes();
    const [showFee, setShowFee] = useState<boolean>(props.package.processingFeeInCents > 0);
    const [showOnBehalfOf, setShowOnBehalfOf] = useState<boolean>(props.package.accountType === AccountType.Silent);
    const [roleFilter, setRoleFilter] = useState<string[] | undefined>();

    const selectedCustomer = Form.useWatch('customer', form);

    const applyRoleFilter = (breakerOnly: boolean) => {
        setRoleFilter(breakerOnly
            ? [Roles.Breaker]
            : undefined
        );
    };

    useEffect(() => {
        applyRoleFilter(props.package.accountType === AccountType.Silent);
    }, [props.package.accountType]);

    const initalValues: EditPackageForm = {
        customer: {
            id: props.package.customerId,
            firstName: props.package.customerFirstName,
            lastName: props.package.customerLastName,
            email: props.package.customerEmail,
            userName: "",
        },
        silentUser: props.package.silentUser
            ? { ...props.package.silentUser, userName: "" }
            : undefined,
        packageTypeId: props.package.packageTypeId,
        status: props.package.packageStatus,
        processingFeeInCents: props.package.processingFeeInCents,
        processingFee: centsToDollars(props.package.processingFeeInCents, false),
        sendProcessingFee: props.package.processingFeeInCents > 0,
        priority: props.package.priority
    };

    const togglePackageTypeOptions = (_: string, option: PackageTypeOption | PackageTypeOption[]) => {
        const opt = option as PackageTypeOption;
        const sendProcessingFee = opt.packageType.processingFeeInCents > 0;

        setShowFee(sendProcessingFee);
        setShowOnBehalfOf(opt.packageType.supportSilentAccounts);
        applyRoleFilter(opt.packageType.supportSilentAccounts);

        form.setFieldsValue({
            sendProcessingFee,
            processingFee: sendProcessingFee ? centsToDollars(opt.packageType.processingFeeInCents, false) : null
        });
    }

    const closeAndResetModal = () => {
        props.onClose();
        form.resetFields();
        const hadSilentAccount = props.package.accountType === AccountType.Silent;

        setShowFee(props.package.processingFeeInCents > 0);
        setShowOnBehalfOf(hadSilentAccount);
        applyRoleFilter(hadSilentAccount);
    };

    const submitForm = () => {
        form.validateFields().then(async (data) => {
            setWorking(true);

            try {
                await updatePackage({
                    id: props.package.id,
                    customer: data.customer!,
                    packageTypeId: data.packageTypeId,
                    packageStatus: data.status,
                    priority: data.priority,
                    processingFeeInCents: data.sendProcessingFee
                        ? data.processingFeeInCents
                        : null,
                    silentUser: data.silentUser
                });

                props.onClose(true);
            } finally {
                setWorking(false);
            }
        });
    };

    const feeValidator = async (
        _: RuleObject,
        price: number
    ): Promise<void> => {
        if (isNaN(price)) {
            return Promise.reject(new Error("Not a valid price!"));
        }

        form.setFieldsValue({ processingFeeInCents: Math.max(price, 0) });
        return Promise.resolve();
    };

    const title =
        props.package.friendlyId > 0
            ? `Edit Package #${props.package.friendlyId}`
            : "Edit Package";

    const isReceived = props.package.packageStatus === PackageStatus.Received;

    return (
        <Modal
            title={title}
            closable={false}
            keyboard={false}
            maskClosable={false}
            destroyOnClose={true}
            open={props.open}
            confirmLoading={working}
            cancelButtonProps={{ disabled: working }}
            okText={working ? "Saving..." : "Update"}
            onOk={submitForm}
            onCancel={closeAndResetModal}
        >
            <Form
                form={form}
                disabled={working}
                initialValues={initalValues}
                layout="vertical"
            >
                <Form.Item
                    label="Customer"
                    name="customer"
                    rules={[
                        {
                            required: true,
                            message: "You must select a customer!",
                        },
                    ]}
                    help={roleFilter !== undefined
                        ? <Alert message="Limited to customers in specific role(s)" type="info" showIcon style={{ fontSize: '12px' }} />
                        : undefined}
                >
                    <CustomerSelector
                        onSelected={(customer) => {
                            form.setFieldsValue({ customer, silentUser: null });
                        }}
                        onClear={() => {
                            form.setFieldsValue({ customer: null, silentUser: null });
                        }}
                        roles={roleFilter}
                    />
                </Form.Item>
                {showOnBehalfOf && <Form.Item
                    label="On Behalf Of"
                    name="silentUser"
                    rules={[
                        {
                            required: true,
                            message: "You must select an account!",
                        },
                    ]}
                >
                    <SilentUserSelector
                        ownerId={selectedCustomer?.id ?? ""}
                        disabled={!selectedCustomer}
                        onSelected={(silentUser) => {
                            form.setFieldsValue({ silentUser });
                        }}
                        onClear={() => {
                            form.setFieldsValue({ silentUser: null });
                        }}
                    />
                </Form.Item>}
                <Form.Item label="Package Type" name="packageTypeId">
                    <Select
                        disabled={types?.length === 0}
                        onChange={togglePackageTypeOptions}
                        options={types?.map(t =>
                            ({ label: t.internalName, value: t.id, packageType: t }))
                        } />
                </Form.Item>
                <Box hidden as={Form.Item} name="sendProcessingFee"><Input hidden /></Box>
                {showFee && <Form.Item label="Processing Fee" name="processingFee" rules={[
                    {
                        required: true,
                        validator: feeValidator,
                        transform: dollarsToCents,
                    },
                ]}>
                    <Input
                        prefix="$"
                        suffix="Per Card, USD"
                    />
                </Form.Item>}
                <Row>
                    <Col span={16}>
                        <Form.Item label="Package Status" name="status">
                            <Radio.Group optionType="button" buttonStyle="solid">
                                <Radio value={PackageStatus.Ebay}>eBay</Radio>
                                <Radio value={PackageStatus.Received}>Received</Radio>
                                <Radio value={PackageStatus.Returned}>Returned</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    {isReceived && <Col span={8}>
                        <Form.Item label="Priority Processing?" name="priority" valuePropName="checked">
                            <Checkbox />
                        </Form.Item>
                    </Col>}
                </Row>
                <Form.Item
                    name="processingFeeInCents"
                    className="has-hidden-input"
                >
                    <Input type="hidden" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditPackageModal;
