import { useQuery } from "@tanstack/react-query";
import { BreakerRoutes, CustomerRoutes } from "appRoutePaths";
import { SideMenuEntry } from "components/Navigation/SideMenu/sideMenuEntries";
import { Roles } from "context/auth";
import { useAuthenticatedRequest } from "./useRequests";
import { UnreadTicketsKeyPrefix } from "./useSupportTickets";

const Dashboard: SideMenuEntry = {
    name: "Dashboard",
    url: CustomerRoutes.dashboard.url,
    icon: require("images/icons/icon_dashboard.svg"),
};
const Profile: SideMenuEntry = {
    name: "Profile",
    url: CustomerRoutes.profile.url,
    icon: require("images/icons/icon_packages.svg"),
};
const Packages: SideMenuEntry = {
    name: "My Submissions",
    url: CustomerRoutes.packages.url,
    icon: require("images/icons/icon_packages.svg"),
};
const Cards: SideMenuEntry = {
    name: "My Cards",
    url: CustomerRoutes.cards.url,
    icon: require("images/icons/icon_cards.svg"),
};
const Transactions: SideMenuEntry = {
    name: "Transactions",
    url: CustomerRoutes.transactions.url,
    icon: require("images/icons/icon_transactions.svg"),
};
const RequestPayout: SideMenuEntry = {
    name: "Request Payout",
    url: CustomerRoutes.requestPayout.url,
    icon: require("images/icons/icon_payout.svg"),
};
const Support: SideMenuEntry = {
    name: "Support",
    url: CustomerRoutes.support.url,
    icon: require("images/icons/icon_support.svg"),
};

const StartSelling: SideMenuEntry = {
    name: "Shop",
    url: CustomerRoutes.shop.url,
    icon: require("images/icons/icon_start_selling.svg"),
};

const BreakerPortal: SideMenuEntry = {
    name: "Breaker Portal",
    url: BreakerRoutes.portal.url,
    icon: require("images/icons/icon_dashboard.svg"),
};

const Breakers: SideMenuEntry = {
    name: "Breakers",
    url: CustomerRoutes.breakers.url,
    icon: require("images/icons/icon_dashboard.svg"),
};

const useUnreadTicketCount = () => {
    const count = useAuthenticatedRequest<{ count: number }>({
        method: "get",
        url: "/support/tickets/unread/count",
    });

    return useQuery([...UnreadTicketsKeyPrefix, "count"], count);
};

const useCustomerMenuItems = (roles: Roles[]): SideMenuEntry[] => {
    const { data, isLoading } = useUnreadTicketCount();

    const extendedSupport = isLoading ? Support : { ...Support, ...data };

    const menuItems = [
        Dashboard,
        Profile,
        StartSelling,
        Packages,
        Cards,
        Transactions,
        RequestPayout,
        extendedSupport,
    ];

    if (roles.includes(Roles.Breaker)) {
        menuItems.push(BreakerPortal);
    } else {
        menuItems.push(Breakers);
    }

    return menuItems;
};

export default useCustomerMenuItems;
