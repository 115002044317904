import { Flex } from "@chakra-ui/react";
import { PublicRoutes as publicPathRoutes } from "appRoutePaths";
import { Cards } from "components/Cards";
import { Navigate } from "components/Navigate";
import { Transactions } from "components/Transactions";
import { WaitTimesBanner } from "components/WaitTimes";
import { BreakerPortalContextProvider } from "components/breaker/PortalContext";
import CustomerDashboard from "components/customer/Dashboard/CustomerDashboard";
import AdminDashboard from "components/staff/Dashboard/AdminDashboard";
import CustomerLayout from "layouts/CustomerLayout";
import PublicLayout from "layouts/PublicLayout";
import StaffLayout from "layouts/StaffLayout";
import TyperLayout from "layouts/TyperLayout";
import BreakerPortal from "pages/breaker/Portal";
import Breakers from "pages/customer/Breakers";
import Checkout from "pages/customer/Checkout";
import ConfirmBreakerLink from "pages/customer/ConfirmBreakerLink";
import CustomerPackages from "pages/customer/CustomerPackages";
import OrderConfirmation from "pages/customer/OrderConfirmation";
import OrderHistory from "pages/customer/OrderHistory";
import Profile from "pages/customer/Profile";
import RequestPayoutPage from "pages/customer/RequestPayoutPage";
import ShopPage from "pages/customer/ShopPage";
import SupportTicketsPage from "pages/customer/SupportTicketsPage";
import ConfirmEmailPage from "pages/public/ConfirmEmail";
import { ContactDetails } from "pages/public/ContactDetails";
import ExternalSupport from "pages/public/ExternalSupport";
import FAQ from "pages/public/FAQ";
import FeaturedAuctions from "pages/public/FeaturedAuctions";
import ForgotPasswordPage from "pages/public/ForgotPassword";
import HomePage from "pages/public/HomePage";
import { HowProcessWorks } from "pages/public/HowProcessWorksPage";
import { Login } from "pages/public/Login";
import PrivacyPolicy from "pages/public/PrivacyPolicy";
import ResetPasswordPage from "pages/public/ResetPassword";
import SellWithUsFAQPage from "pages/public/SellWithUsFAQ";
import { SignUp } from "pages/public/SignUp";
import { TwoFactor } from "pages/public/TwoFactor";
import AuditLog from "pages/staff/AuditLog";
import CardTitleQADashboard from "pages/staff/CardTitleQA";
import StaffCards from "pages/staff/Cards";
import DeadMessages from "pages/staff/DeadMessages";
import FinancialSummary from "pages/staff/FinancialSummary";
import MandatoryMFA from "pages/staff/MandatoryMFA";
import NewPackage from "pages/staff/NewPackage";
import OrderFulfillment from "pages/staff/OrderFulfillment";
import ShipmentBatchCards from "pages/staff/ShipmentBatchCards";
import ShipmentBatchMissingCards from "pages/staff/ShipmentBatchMissingCards";
import ShipmentBatchShipments from "pages/staff/ShipmentBatchShipments";
import ShipmentBatches from "pages/staff/ShipmentBatches";
import ShipmentLabelReplacement from "pages/staff/ShipmentLabelReplacement";
import Shipments from "pages/staff/Shipments";
import StaffPackageTypes from "pages/staff/StaffPackageTypes";
import StaffPackages from "pages/staff/StaffPackages";
import StaffPayoutsPage from "pages/staff/StaffPayouts";
import StaffTransactions from "pages/staff/Transactions";
import TyperAssignment from "pages/staff/TyperAssignment";
import UnsoldCardBatches from "pages/staff/UnsoldCardBatches";
import UnsoldCards from "pages/staff/UnsoldCards";
import UsersPage from "pages/staff/UsersPage";
import WebsiteUpdates from "pages/staff/WebsiteUpdates";
import CardTitleAssignment from "pages/typer/CardTitleAssignment";
import { CardTitleContextProvider } from "pages/typer/CardTitleContext";
import OpenCardFolder from "pages/typer/OpenCardFolder";
import { TyperDashboard } from "pages/typer/TyperDashboard";
import { FC, PropsWithChildren } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

const LayoutWithWaitTimesBanner: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Flex flexDir="column" bg="var(--dcs-dark-blue)">
            <WaitTimesBanner />
            {children ?? <Outlet />}
        </Flex>
    );
};

export const TODORoute = Outlet;

const PublicRoutes: FC = () => {
    return (
        <Routes>
            <Route path="/" element={<PublicLayout />}>
                <Route path="" element={<LayoutWithWaitTimesBanner />}>
                    <Route index element={<HomePage />} />
                    <Route
                        path="how-process-works"
                        element={<HowProcessWorks />}
                    />
                    <Route
                        path="featured-auctions"
                        element={<FeaturedAuctions />}
                    />
                </Route>
                <Route path="login" element={<Login />} />
                <Route path="two-factor" element={<TwoFactor />} />
                <Route path="wp-login.php" element={<Navigate to="/login" />} />
                <Route path="sign-up" element={<SignUp />} />
                <Route
                    path="forgot-password"
                    element={<ForgotPasswordPage />}
                />
                <Route path="reset-password" element={<ResetPasswordPage />} />
                <Route path="confirm-email" element={<ConfirmEmailPage />} />
                <Route path="faq" element={<FAQ />} />
                <Route path="sell-with-us" element={<SellWithUsFAQPage />} />
                <Route
                    path={publicPathRoutes.contactDetails.routePath}
                    element={<ContactDetails />}
                />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="external-support" element={<ExternalSupport />} />
            </Route>
        </Routes>
    );
};

const CustomerRoutes: FC = () => {
    return (
        <Routes>
            <Route path="/customer" element={<CustomerLayout />}>
                <Route path="dashboard" element={<CustomerDashboard />} />
                <Route path="packages" element={<CustomerPackages />} />
                <Route path="cards" element={<Cards />} />
                <Route path="transactions" element={<Transactions />} />
                <Route path="request-payout" element={<RequestPayoutPage />} />
                <Route path="support" element={<SupportTicketsPage />} />
                <Route path="profile" element={<Profile />} />
                <Route path="shop" element={<ShopPage />} />
                <Route path="shop/checkout" element={<Checkout />} />
                <Route path="shop/orders" element={<OrderHistory />} />
                <Route
                    path="shop/orders/:orderId/confirm"
                    element={<OrderConfirmation />}
                />
                <Route path="breakers" element={<Breakers />} />
                <Route path="confirm-breaker-link" element={<ConfirmBreakerLink />} />
                <Route index element={<Navigate to="/dashboard" />} />
            </Route>
        </Routes>
    );
};

const BreakerRoutes: FC = () => {
    return (
        <Routes>
            <Route path="/breaker" element={
                <BreakerPortalContextProvider>
                    <CustomerLayout />
                </BreakerPortalContextProvider>}>

                <Route path="portal" element={<BreakerPortal />} />
            </Route>
        </Routes>
    )
}

const TyperRoutes: FC = () => {
    return (
        <Routes>
            <Route path="/processor" element={<TyperLayout />}>
                <Route path="dashboard" element={<TyperDashboard />} />
                <Route path="card-titling/:cardFolderId" element={<OpenCardFolder />} />
                <Route path="card-titling/:cardFolderId/card/:cardId" element={
                    <CardTitleContextProvider>
                        <CardTitleAssignment />
                    </CardTitleContextProvider>} />
                <Route index element={<Navigate to="/dashboard" />} />
            </Route>
        </Routes>
    );
};

const StaffRoutes: FC = () => {
    return (
        <Routes>
            <Route path="/staff">
                {/* Staff pages that render the left-hand navigation menu */}
                <Route element={<StaffLayout showSideMenu={true} />}>
                    <Route path="dashboard" element={<AdminDashboard />} />
                    <Route path="payouts" element={<StaffPayoutsPage />} />
                    <Route path="cards" element={<StaffCards />} />
                    <Route path="users" element={<UsersPage />} />
                    <Route
                        path="website-updates"
                        element={<WebsiteUpdates />}
                    />
                    <Route
                        path="financial-summary"
                        element={<FinancialSummary />}
                    />
                    <Route path="packages" element={<StaffPackages />} />
                    <Route path="packages/new" element={<NewPackage />} />
                    <Route
                        path="transactions"
                        element={<StaffTransactions />}
                    />
                    <Route path="orders" element={<OrderFulfillment />} />
                    <Route path="audit-log" element={<AuditLog />} />
                    <Route
                        path="package-types"
                        element={<StaffPackageTypes />}
                    />
                </Route>

                {/* Staff pages that do not show the left-hand navigation menu */}
                <Route element={<StaffLayout showSideMenu={false} />}>
                    <Route path="shipment-batches">
                        <Route index element={<ShipmentBatches />} />
                        <Route
                            path=":shipmentBatchId"
                            element={<Shipments />}
                        />
                        <Route
                            path=":shipmentBatchId/cards"
                            element={<ShipmentBatchCards />}
                        />
                        <Route
                            path=":shipmentBatchId/shipments"
                            element={<ShipmentBatchShipments />}
                        />
                        <Route
                            path=":shipmentBatchId/shipments/:shipmentId"
                            element={<ShipmentBatchShipments />}
                        />
                        <Route
                            path=":shipmentBatchId/missing-cards"
                            element={<ShipmentBatchMissingCards />}
                        />
                        <Route
                            path="dead-messages"
                            element={<DeadMessages />}
                        />
                        <Route
                            path="new-shipment-label"
                            element={<ShipmentLabelReplacement />}
                        />
                    </Route>
                    <Route path="unsold-card-batches">
                        <Route index element={<UnsoldCardBatches />} />
                        <Route
                            path=":unsoldCardBatchId/cards"
                            element={<UnsoldCards />}
                        />
                    </Route>
                    <Route
                        path="typer-assignment"
                        element={<TyperAssignment />}
                    />
                    <Route
                        path="card-title-qa"
                        element={<CardTitleQADashboard />}
                    />
                    <Route
                        path="two-factor-required"
                        element={<MandatoryMFA />}
                    />
                </Route>

                <Route index element={<Navigate to="/dashboard" />} />
            </Route>
        </Routes>
    );
};

const AppRoutes: FC = () => {
    return (
        <>
            <TyperRoutes />
            <StaffRoutes />
            <BreakerRoutes />
            <CustomerRoutes />
            <PublicRoutes />
        </>
    );
};

export default AppRoutes;
