import Currency from "components/Currency";

const ConfirmationStep = (props: {
    toCustomerInCents: number,
    description: string;
    active: boolean;
}) => {
    const { active, toCustomerInCents, description } = props;

    if (!active || isNaN(toCustomerInCents)) {
        return <></>;
    }

    return <div id="confirm-message">
        <div className="content">
            This customer will receive an adjustment of{" "}
            <Currency cents={toCustomerInCents} /> to their account.
        </div>
        <div className="content">
            Your breaks account will receive an adjustment of{" "}<Currency cents={-toCustomerInCents} />
        </div>
        <div className="content">
            <span className="bold-font">Reason:</span> {description}
        </div>
    </div>;
};

export default ConfirmationStep;