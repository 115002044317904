import { useMutation } from "@tanstack/react-query";
import { Card } from "antd";
import { CustomerRoutes } from "appRoutePaths";
import FullScreenSpinner from "components/FullScreenSpinner";
import { useAuthenticatedRequestCreator } from "hooks/useRequests";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

interface ConfirmLinkParams {
    token?: string;
    email?: string;
}

const useConfirmBreakerLink = () => {
    const requestLinkConfirmation = useAuthenticatedRequestCreator<
        void,
        ConfirmLinkParams
    >((data) => ({
        url: "/customer/breakers/confirm-link",
        method: "POST",
        data,
    }));

    return useMutation(requestLinkConfirmation);
};

const ConfirmationState = (props: { isSuccess: boolean, isIdle: boolean, isLoading: boolean }) => {
    if (props.isIdle || props.isLoading) {
        return <p>Validating your breaker link request...</p>;
    }

    if (!props.isSuccess) {
        return <p>There was a problem confirming your link with the breaker!</p>;
    }

    return <>
        <p>
            Your connection with the breaker has been made!
        </p>
        <p>
            You will be redirected to your
            &nbsp;<a href={CustomerRoutes.breakers.url} style={{ color: 'blue', textDecoration: 'underline' }}>breaker dashboard</a>&nbsp;
            in 10 seconds.
        </p>
    </>;
}

const ConfirmBreakerLink = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token") as string | undefined;
    const email = searchParams.get("email") as string | undefined;
    const navigate = useNavigate();

    const { mutateAsync: confirmLink, isLoading, isSuccess, isIdle } = useConfirmBreakerLink();

    useEffect(() => {
        if (token && email) {
            confirmLink({ token, email });
        }
    }, [confirmLink, email, token]);

    useEffect(() => {
        if (isSuccess) {
            setTimeout(function () {
                navigate(CustomerRoutes.breakers.url, { replace: true });
            }, 10000);
        }
    }, [navigate, isSuccess]);

    return <Card bodyStyle={{ margin: '30px' }}>
        <Card.Grid style={{ textAlign: 'center', width: '100%', fontSize: '30px' }}>
            <FullScreenSpinner visible={isLoading} />
            <ConfirmationState
                isLoading={isLoading}
                isIdle={isIdle}
                isSuccess={isSuccess}
            />
        </Card.Grid>
    </Card>;
};

export default ConfirmBreakerLink;