import { Alert, Col, DatePicker, Form, Input, Modal, Row, Space, UploadFile } from "antd";
import axios from "axios";
import { CardPhotoType, MaxTitleLength } from "models/cardIngestion";
import { StaffSportsCard } from "models/cards";
import { DisplayDateFormat } from "models/websiteSettings";
import moment from "moment-timezone";
import { useCallback, useEffect, useState } from "react";
import { CardUpdateCallback } from "./EditCardModal";
import UploadCardImages, { CardImageUploadResult } from "./UploadCardImages";
import { useCardRelist, useLiveCardImages } from "./useCardRelistHooks";

interface RelistModalProps {
    open: boolean;
    onClose: () => void;
    card: StaffSportsCard;
    onSuccess: CardUpdateCallback;
}

interface ImageDefaults {
    frontImage: string[];
    backImage: string[];
    extraImages: string[];
}

interface RelistError {
    errors: string[];
}

const RelistModal = ({ open, onClose, card, onSuccess }: RelistModalProps) => {
    const [fetched, setFetched] = useState(false);
    const [files, setFiles] = useState<CardImageUploadResult[]>([]);
    const [defaultImages, setDefaultImages] = useState<ImageDefaults>({ frontImage: [], backImage: [], extraImages: [] });
    const [error, setError] = useState<RelistError>();
    const [form] = Form.useForm<{ cardTitle: string, scheduledDate: moment.Moment }>();
    const { fetchImages, isLoadingImages } = useLiveCardImages(card.cardId);
    const { relistCard, isRelisting } = useCardRelist();

    const loading = isLoadingImages || isRelisting;

    useEffect(() => {
        async function fetch() {
            const result = await fetchImages();

            setDefaultImages({
                frontImage: result.frontImage ? [result.frontImage] : [],
                backImage: result.backImage ? [result.backImage] : [],
                extraImages: [...result.extraImages]
            });
        }

        if (open && !fetched) {
            setFetched(true);
            fetch();
        }
    }, [open, card, fetched, fetchImages]);

    const resetAndClose = () => {
        form.resetFields();
        setError(undefined);
        onClose();
    }

    const imageAdded = useCallback((result: CardImageUploadResult) => {
        setFiles((cur) => [...cur, result]);
    }, []);

    const imagePreviewed = useCallback((file: UploadFile) => {
        const img = files.find(f => f.uid === file.uid);
        if (img) {
            window.open(img.url, "_blank");
        }
    }, [files]);

    const imageRemoved = useCallback((uid: string) => {
        setFiles((cur) => [...cur.filter(x => x.uid !== uid)]);
    }, []);

    const submit = async () => {
        setError(undefined);

        const data = await form.validateFields();
        const payload = {
            cardId: card.cardId,
            newTitle: data.cardTitle,
            scheduledDate: data.scheduledDate.toISOString(),
            frontImageUrl: files.find(f => f.type === CardPhotoType.Front)?.url ?? null,
            backImageUrl: files.find(f => f.type === CardPhotoType.Back)?.url ?? null,
            extraImageUrls: files.filter(f => f.type === CardPhotoType.Extra).map(x => x.url!)
        }

        try {
            await relistCard(payload);
            await onSuccess(card.cardId);

            resetAndClose();
        } catch (err) {
            if (axios.isAxiosError(err)) {
                setError((err.response?.data as RelistError));
            } else {
                setError({ errors: ['an unknown error occurred!'] });
            }
        }
    }

    return <Modal
        width='750px'
        title="Relist Card on eBay"
        open={open}
        closable={false}
        keyboard={false}
        maskClosable={false}
        confirmLoading={loading}
        cancelButtonProps={{ disabled: loading }}
        onOk={submit}
        onCancel={resetAndClose}
        destroyOnClose
        okButtonProps={{ disabled: files.some(x => !x.ok) }}
    >
        <Form
            form={form}
            layout='vertical'
            disabled={loading}
            initialValues={{
                cardTitle: card.title,
                scheduledDate: moment().set({ hour: 21, minute: 0, second: 0, millisecond: 0 })
            }}>
            <Row>
                <Col span={24}>
                    <Form.Item
                        label="Card Title"
                        name="cardTitle"
                        rules={[
                            { required: true, message: 'Card title is required' },
                            { max: MaxTitleLength, message: `Card title has to be ${MaxTitleLength} characters or less` }
                        ]}>
                        <Input
                            showCount={{ formatter: (args) => `${args.count} / ${MaxTitleLength}` }}
                            spellCheck
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Space direction="horizontal">
                        <UploadCardImages
                            defaults={defaultImages.frontImage}
                            cardId={card.cardId}
                            type={CardPhotoType.Front}
                            onAdded={imageAdded}
                            onPreview={imagePreviewed}
                            onRemove={imageRemoved}
                            disabled={loading}
                        />
                        <UploadCardImages
                            defaults={defaultImages.backImage}
                            cardId={card.cardId}
                            type={CardPhotoType.Back}
                            onAdded={imageAdded}
                            onPreview={imagePreviewed}
                            onRemove={imageRemoved}
                            disabled={loading}
                        />
                        <UploadCardImages
                            defaults={defaultImages.extraImages}
                            cardId={card.cardId}
                            type={CardPhotoType.Extra}
                            onAdded={imageAdded}
                            onPreview={imagePreviewed}
                            onRemove={imageRemoved}
                            disabled={loading}
                        />
                    </Space>
                </Col>
                <Col span={8} style={{ marginTop: '12px' }}>
                    <Form.Item
                        label="Relist Go-Live Date/Time"
                        name="scheduledDate"
                        rules={[
                            { required: true, message: 'Go-live date/time is required' }
                        ]}>
                        <DatePicker
                            style={{ width: '100%' }}
                            format={`${DisplayDateFormat} LT`}
                            showNow={false}
                            showTime={{ format: 'h:mm a' }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    {error && error.errors.map(e => <Alert type="error" message={e} />)}
                </Col>
            </Row>
        </Form>
    </Modal>
};

export default RelistModal;