import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useIdentity } from "context/auth";
import { AccountSummary } from "models/accountSummary";
import { useAuthenticatedRequest } from "./useRequests";

const QUERY_KEY = "summary";

export const useInvalidateAccountSummary = (accountId?: string | null) => {
    const { accounts } = useIdentity();
    const accountIdToInvalidate = accountId ?? accounts[0];
    const queryClient = useQueryClient();
    return () => {
        queryClient.invalidateQueries([accountIdToInvalidate, QUERY_KEY]);
    };
};

const useAccountSummary = (
    accountId?: string | null,
    options?: { suspense: boolean }
) => {
    const getAccountSummary = useAuthenticatedRequest<AccountSummary>({
        method: "get",
        url: `/customer-account/${accountId}/summary`,
    });

    return useQuery([accountId, QUERY_KEY], getAccountSummary, { ...options, enabled: !!accountId });
};

export default useAccountSummary;
