import "styles/customer_info.css";

import { Stack, VStack } from "@chakra-ui/layout";
import { WaitTimesBanner } from "components/WaitTimes";
import { useIdentity } from "context/auth";
import useAccountSummary from "hooks/useAccountSummary";
import {
    centsToDollars,
    pageRequestToUrlSearchParams,
    valueOrUnknown,
} from "util/helpers";

import { CustomerRoutes } from "appRoutePaths";
import { DefaultPageRequest } from "util/getDefaultPageRequest";
import { CustomerInfoBox } from "./CustomerInfoBox";

// Awaiting Payment - Cards Page -> Filtered by status = "awaiting payment"
// See All Listed - Packages Page -> Filtered by packages with an upload date
const cardsAwaitingPaymentFilter = pageRequestToUrlSearchParams({
    ...DefaultPageRequest,
    filterBy: {
        status: ["AwaitingPayment"],
    },
});
const cardsAwaitingPaymentLink = `${CustomerRoutes.cards.url}?${cardsAwaitingPaymentFilter}`;

export const CustomerInfoBanner = () => {
    const { accounts } = useIdentity();
    const { isLoading, data } = useAccountSummary(accounts[0]);

    const availableBalance = valueOrUnknown(
        data?.availableBalanceInCents,
        centsToDollars
    );
    const moneyOnTheWay = valueOrUnknown(
        data?.moneyOnTheWayInCents,
        centsToDollars
    );
    const activeListings = valueOrUnknown(
        data?.activeListingsInCents,
        centsToDollars
    );

    return (
        <VStack width="100%" bg="var(--dcs-dark-blue)" color="var(--dcs-black)" borderBottom="2px solid white">
            <Stack direction="row">
                <WaitTimesBanner />
            </Stack>
            <Stack
                direction={{
                    base: "column",
                    lg: "row",
                }}
                width="100%"
                py="4"
                px="24"
                spacing="4"
            >
                <CustomerInfoBox
                    isLoading={isLoading}
                    title="Available Balance"
                    subTitle="Amount of money you can cash out."
                    focusText={availableBalance}
                    linkUrl={CustomerRoutes.requestPayout.url}
                    linkText="Request Payout"
                />
                <CustomerInfoBox
                    isLoading={isLoading}
                    title="Money on the way!"
                    subTitle="As soon as we get paid, you'll get paid."
                    focusText={moneyOnTheWay}
                    linkUrl={cardsAwaitingPaymentLink}
                    linkText="See Cards Awaiting Payment"
                />
                <CustomerInfoBox
                    isLoading={isLoading}
                    title="Active Listings"
                    subTitle="Amount you'll receive from your current live auctions."
                    focusText={activeListings}
                    linkUrl={data?.activeListingsEbayLink}
                    linkText={data?.activeListingsEbayLink && "See All Listed"}
                    isLinkExternal={true}
                />
            </Stack>
        </VStack>
    );
};
