import KeyValueTableFilter from "components/KeyValueTableFilter";
import { ClearTableFilterRequest } from "models/common";
import { ExtendedFilterDropdownProps } from "util/table";

const SaleType = "Sale";
const ManAdjType = "ManualAdjustment";
const PayoutType = "Payout";
const CreditUsedType = "CreditUsed";
const UserTransfer = "UserTransfer";

const BreakerTransactionTypeFilter = ({
    prefixCls,
    visible,
    confirm,
    selectedKeys,
    setSelectedKeys,
    clearFilters,
    onFilter,
}: ExtendedFilterDropdownProps) => {
    return (
        <KeyValueTableFilter
            prefixCls={prefixCls}
            visible={visible}
            confirm={confirm}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            clearFilters={clearFilters}
            onFilter={onFilter}
            clearTableFilterRequest={ClearTableFilterRequest.Type}
            keyValuePairs={{
                "Card Sale": SaleType,
                "Credit Used": CreditUsedType,
                "Manual Adjustment": ManAdjType,
                Payout: PayoutType,
                "User Transfer": UserTransfer
            }}
        />
    );
};

export default BreakerTransactionTypeFilter;
