import { Box, Flex, Stack, VStack } from "@chakra-ui/react";
import { BreakerRoutes } from "appRoutePaths";
import { BreakerInfoBanner } from "components/breaker/BreakerInfoBanner";
import { CustomerInfoBanner } from "components/CustomerInfoBanner";
import { ImpersonatingBanner } from "components/ImpersonatingBanner";
import { Navigate } from "components/Navigate";
import { Footer } from "components/Navigation/Footer";
import { NavHeader } from "components/Navigation/NavHeader";
import { SideMenu } from "components/Navigation/SideMenu/SideMenu";
import { Roles } from "context/auth";
import { useContactDetailsRedirect } from "hooks/useContactDetails";
import useCustomerMenuItems from "hooks/useCustomerMenuItems";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import useIdentityRedirection from "./useIdentityRedirection";

enum InfoBannerType {
    Customer = 0,
    Breaker = 1
}

const InfoBanner: FC<{ visible: boolean, type: InfoBannerType }> = ({ visible, type }) => {
    if (!visible) {
        return null;
    } else if (type === InfoBannerType.Breaker) {
        return <BreakerInfoBanner />;
    }

    return <CustomerInfoBanner />;
}

const CustomerLayout = ({ children }: any) => {
    useContactDetailsRedirect();

    const { shouldRedirect, destination, roles } = useIdentityRedirection([Roles.User, Roles.Breaker]);
    const menuItems = useCustomerMenuItems(roles);

    if (shouldRedirect) {
        return <Navigate to={destination!} replace />;
    }

    // HACK: Not so bad, but much better ways exist to hide the <CustomerInfo /> on some pages (support)
    const isOnSupportPage = window.location.pathname.includes("support");
    const bannerType = roles.includes(Roles.Breaker) && window.location.pathname.includes(BreakerRoutes.portal.url)
        ? InfoBannerType.Breaker
        : InfoBannerType.Customer;

    return (
        <Flex
            flexDir="column"
            w="100%"
            justifyContent="space-between"
            minH="100vh"
            backgroundColor="black"
            color="var(--dcs-white)"
        >
            <NavHeader />
            <ImpersonatingBanner />
            <Stack
                direction={{ base: "column", md: "row" }}
                align="start"
                spacing={0}
                width="100vw"
                flex={1}
            >
                <SideMenu entries={menuItems} />
                <VStack alignItems="baseline" w="100%" h="100%">
                    <InfoBanner
                        visible={!isOnSupportPage}
                        type={bannerType}
                    />
                    <Box
                        px={{ base: 5, md: 15 }}
                        py={3}
                        width="100%"
                    >
                        {children ? children : <Outlet />}
                    </Box>
                </VStack>
            </Stack>
            <Footer />
        </Flex>
    );
};

export default CustomerLayout;
