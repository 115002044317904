import { useMutation } from "@tanstack/react-query";
import { Alert, Button, Col, Form, Input, Modal, Row } from "antd";
import { useAuthenticatedRequestCreator } from "hooks/useRequests";
import { BreakerCustomer } from "models/breaker";
import { useEffect, useState } from "react";
import { useBreakerDataRefresh } from "./createBreakerHooks";

interface EditCustomerForm {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

const useEditCustomer = () => {
    const { onCustomerEdit: onSuccess } = useBreakerDataRefresh();

    const update = useAuthenticatedRequestCreator<void, EditCustomerForm>((data) => ({
        method: "post",
        url: "/breaker/users/update",
        data
    }));

    const { mutateAsync: editCustomer, isLoading } = useMutation(update, {
        onSuccess
    });

    return { editCustomer, isLoading };
}

const EditCustomerModal = (props: { customer: BreakerCustomer }) => {
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm<EditCustomerForm>();
    const [dirty, setDirty] = useState(false);
    const { editCustomer, isLoading } = useEditCustomer();
    const [failed, setFailed] = useState(false);
    const { customer } = props;

    const closeAndResetModal = () => {
        setOpen(false);
        setDirty(false);
        setFailed(false);
        form.resetFields();
    };

    useEffect(() => {
        if (open) {
            form.setFieldsValue(customer);
        }
    }, [open, customer, form]);

    const submit = async () => {
        setFailed(false);
        if (!dirty) {
            closeAndResetModal();
            return;
        }

        try {
            const data = await form.validateFields();
            await editCustomer(data);
            closeAndResetModal();
        }
        catch {
            setFailed(true);
        }
    }

    return <>
        <Button onClick={() => setOpen(true)}>
            Edit Customer
        </Button>
        <Modal
            title={`Edit Customer - ${customer.userName}`}
            closable={false}
            keyboard={false}
            maskClosable={false}
            destroyOnClose
            open={open}
            onOk={submit}
            onCancel={closeAndResetModal}
            confirmLoading={isLoading}
            cancelButtonProps={{ disabled: isLoading }}
        >
            <Form
                form={form}
                disabled={isLoading}
                initialValues={customer}
                layout="vertical"
                onFieldsChange={() => setDirty(true)}
            >
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[{ required: true, message: "First name is required!" }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[{ required: true, message: "Last name is required!" }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[{ required: true, message: "Email address is required!" }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                {failed && <Row>
                    <Col span={24}>
                        <Alert
                            message="An unexpected error occurred trying to update this customer"
                            type="error"
                            showIcon />
                    </Col>
                </Row>}
                <Form.Item hidden name="id">
                    <Input type="hidden" />
                </Form.Item>
            </Form>
        </Modal>
    </>;
};

export default EditCustomerModal;