import EntitySelector, { EntitySelectorProps, EntitySelectorRef } from "components/EntitySelector";
import MultiValueDisplay from "components/MultiValueDisplay";
import { useAuthenticatedRequestCreator } from "hooks/useRequests";
import { BreakerCustomerSearchResult } from "models/breaker";
import { forwardRef, Ref } from "react";

interface BreakerCustomerSelectorProps {
    includeOwner?: boolean
}

const useBreakerCustomerSearch = () => {
    const search = useAuthenticatedRequestCreator<BreakerCustomerSearchResult[], { term: string, includeOwner: boolean }>(
        data => ({
            method: "get",
            url: `/breaker/users/search?term=${data.term}&includeOwner=${data.includeOwner}`
        })
    );

    return { search };
}

const resultDisplay = ({ userName, firstName, lastName }: BreakerCustomerSearchResult) => {
    return <MultiValueDisplay
        title={userName}
        subTitle={`${firstName} ${lastName}`}
    />;
};

const BreakerCustomerSelector = (
    props: EntitySelectorProps<BreakerCustomerSearchResult> & BreakerCustomerSelectorProps,
    ref: Ref<EntitySelectorRef>
) => {
    const { search } = useBreakerCustomerSearch();
    const { includeOwner = false } = props;

    return <EntitySelector
        ref={ref}
        {...props}
        placeholder={props.placeholder ?? "First, last or username"}
        search={term => search({ term, includeOwner })}
        resultDisplay={resultDisplay}
    />
};

export default forwardRef<
    EntitySelectorRef,
    EntitySelectorProps<BreakerCustomerSearchResult> & BreakerCustomerSelectorProps
>(BreakerCustomerSelector);