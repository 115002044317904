import { CalculatorOutlined } from "@ant-design/icons";
import { HStack, Text } from "@chakra-ui/react";
import { Button, DatePicker, Form, Input, List, Modal, Select } from "antd";
import MultiValueDisplay from "components/MultiValueDisplay";
import useAvailablePackageTypes from "hooks/useAvailablePackageTypes";
import usePackageTypeUpdate from "hooks/usePackageTypeUpdate";
import { FeeBreakdown, FeeTierNames, FeeTierRange } from "models/packages";
import { FC, useState } from "react";
import { centsToDollars, dollarsToCents } from "util/helpers";
import { feeValidator } from "./helpers";

const DryRunModal: FC = () => {
    const { data, refetch } = useAvailablePackageTypes(false);
    const { dryRun } = usePackageTypeUpdate();
    const [visible, setVisible] = useState<boolean>(false);
    const [working, setWorking] = useState<boolean>(false);
    const [result, setResult] = useState<FeeBreakdown | undefined>();
    const [form] = Form.useForm();

    const calculate = async () => {
        form.validateFields().then(async (form) => {
            try {
                setWorking(true);
                const response = await dryRun(form);
                setResult(response);
            } finally {
                setWorking(false);
            }
        });
    }

    const open = async () => {
        if (data) {
            // types may have been added / enabled / disabled
            // while the modal was closed, so ensure an 
            // up-to-date available package list
            await refetch();
        }
        setVisible(true);
    }

    const close = () => {
        setResult(undefined);
        setVisible(false);
    }

    const resultMap = () => [
        { title: 'Fee Tier', value: <MultiValueDisplay title={FeeTierNames[result!.feeTier]} subTitle={FeeTierRange[result!.feeTier]} /> },
        { title: 'Fee Source', value: result!.isDefaultFee ? `Default Fees (${result!.source})` : 'Admin Provided' },
        { title: 'Processing Fee', value: result!.processingFeeInCents > 0 ? centsToDollars(result!.processingFeeInCents) : 'N/A' },
        { title: 'Percentage of Sale', value: result!.percentageInPoints ? `${result!.percentageInPoints}%` : 'N/A' },
        { title: 'Flat Fee', value: result!.flatFeeInCents ? centsToDollars(result!.flatFeeInCents) : 'N/A' },
        { title: 'Total Fees', value: centsToDollars(result!.totalFeesInCents) },
        { title: 'Net to Customer', value: centsToDollars(result!.netToCustomerInCents) },
    ];

    return <>
        <Button
            onClick={open}
            icon={<CalculatorOutlined />}
            type="link"
            size="large"
            title="Fee Calculator"
        >Fee Calculator</Button>
        <Modal
            open={visible}
            destroyOnClose
            onCancel={close}
            title="Fee Calculator"
            footer={[<Button key='cancel' onClick={close}>Close</Button>]}
        >
            <HStack display='flex' justifyContent='space-around'>
                <Form
                    form={form}
                    preserve={false}
                    labelCol={{ span: 10 }}
                    layout="horizontal"
                >
                    <Form.Item
                        label="Sale Price"
                        name="grossAmount"
                        rules={[
                            { required: true, transform: dollarsToCents, validator: (r, v) => feeValidator(form, r, v) },
                            /*{ validator: minimumSalePrice }*/
                        ]}>
                        <Input prefix='$' type="number" placeholder="0.00" />
                    </Form.Item>
                    <Form.Item label="Package Type" name="packageTypeId" rules={[{ required: true, message: "Select a package type!" }]}>
                        <Select
                            disabled={data?.length === 0}
                            options={data?.map(t =>
                                ({ label: t.internalName, value: t.id }))
                            } />
                    </Form.Item>
                    <Form.Item label="Pkg. Rec. Date" name="receivedDate" rules={[{ required: false }]}>
                        <DatePicker placeholder="Received Date" />
                    </Form.Item>
                    <Form.Item
                        hidden
                        name="grossAmountInCents">
                        <Input />
                    </Form.Item>
                </Form>
                <Button size="large" type="primary" onClick={calculate} disabled={working}>
                    Calculate
                </Button>
            </HStack>
            {result && <List
                dataSource={resultMap()}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            title={<Text fontFamily='body' fontWeight={500}>{item.title}</Text>}
                        />
                        <Text fontWeight={500} fontSize='lg'>{item.value}</Text>
                    </List.Item>
                )}
            />}
        </Modal>
    </>;
}

export default DryRunModal;