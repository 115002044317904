import { StaffSportsCardOrder } from "models/cards";
import { useAuthenticatedRequestCreator } from "./useRequests";

export interface StaffCardUpdate {
    cardId: string;
    batchId: string;
    customerId: string;
    accountId: string | null;
    salePriceInCents: number;
    title: string;
    status: string;
    listingDate: Date | undefined;
    saleDate: Date | undefined;
    order: StaffSportsCardOrder | undefined;
}

const useSingleCardUpdate = () => {
    const update = useAuthenticatedRequestCreator<boolean, StaffCardUpdate>(
        (card) => ({
            method: "post",
            url: `/cards/update`,
            data: card,
        })
    );

    return { update };
};

export default useSingleCardUpdate;
