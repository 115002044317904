import { FireTwoTone } from "@ant-design/icons";
import { Box } from "@chakra-ui/react";
import { Button, Col, Dropdown, Menu, Row } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { FilterValue } from "antd/lib/table/interface";
import { StaffRoutes } from "appRoutePaths";
import { AccountSearch, PackageTypeSearch } from "components/EntityTableSearch";
import AmountFilter from "components/staff/Packages/AmountFilter";
import BatchTable from "components/staff/Packages/BatchTable";
import EditPackageModal from "components/staff/Packages/EditPackageModal";
import FriendlyIdFilter from "components/staff/Packages/FriendlyIdFilter";
import StaffPackagesFilter, {
    StaffPackagesFilterDetail,
} from "components/staff/Packages/StaffPackagesFilter";
import StatusFilter from "components/staff/Packages/StatusFilter";
import TableActions from "components/TableActions";
import useStaffPackages, { useStaffPackagesCSV } from "hooks/useStaffPackages";
import { InitialPageRequest, PageRequest, PageSizeOptions } from "models/common";
import {
    PackageAmount,
    PackageStatus,
    StaffPackage,
} from "models/packages";
import { useState } from "react";
import { Link } from "react-router-dom";
import downloadAsCSV from "util/downloadAsCSV";
import { localizedDate } from "util/helpers";
import { createCustomFilter, packageTypeDisplay } from "util/table";
import CustomerDisplay from "./CustomerDisplay";
import PackageDetailModal from "./PackageDetailModal";
import ReturnPackageModal from "./ReturnCardsModal";
import SendUpdateModal from "./SendUpdateModal";

const PackageActions = (props: {
    package: StaffPackage;
    onEdit: () => Promise<void>;
}) => {
    const [editOpen, setEditOpen] = useState(false);
    const [returnOpen, setReturnOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);

    const openQRCodeTab = () => {
        window.open(StaffRoutes.packageQRCode.url(props.package.id), '_blank');
    };

    return (
        <>
            <Dropdown.Button
                onClick={() => setEditOpen(true)}
                overlay={(() => (
                    <Menu items={[
                        {
                            key: "return",
                            label: "Return Card",
                            onClick: () => setReturnOpen(true)
                        },
                        {
                            key: "update",
                            label: "Send Update",
                            onClick: () => setUpdateOpen(true)
                        },
                        {
                            key: "qr",
                            label: "Print QR Code",
                            onClick: openQRCodeTab
                        }
                    ]} />
                ))()}
            >
                Edit
            </Dropdown.Button>
            <EditPackageModal
                open={editOpen}
                onClose={async (edited = false) => {
                    setEditOpen(false);
                    edited && await props.onEdit();
                }}
                package={props.package}
            />
            <ReturnPackageModal
                open={returnOpen}
                onClose={async (edited = false) => {
                    setReturnOpen(false);
                    edited && await props.onEdit();
                }}
                package={props.package}
            />
            <SendUpdateModal
                open={updateOpen}
                onClose={async (edited = false) => {
                    setUpdateOpen(false);
                    edited && await props.onEdit();
                }}
                package={props.package}
            />
        </>
    );
};

const AllPackages = () => {
    const [{ page, filterBy, withinDays, sort, pageSize }, setPageRequest] =
        useState<PageRequest>(InitialPageRequest);

    const { isLoading, data, refetch } = useStaffPackages({
        page,
        filterBy,
        withinDays,
        sort,
        pageSize
    });
    const [filterDetail, setFilterDetail] = useState<StaffPackagesFilterDetail>(
        {}
    );
    const { data: records, totalRecords } = data || {};

    const daysChanged = (value: number) => {
        setPageRequest({ page: 1, filterBy, withinDays: value, sort, pageSize });
    };

    const tableChanged = (
        // pagination
        { current: newPage, pageSize: newPageSize }: any,
        // filtering
        filters: Record<string, FilterValue | null>,
        // sorting
        { column, field, order }: any
    ) => {
        setPageRequest({
            page: newPageSize !== pageSize ? 1 : newPage,
            pageSize: newPageSize,
            filterBy: filters,
            withinDays,
            sort: column ? [field, order === "ascend"] : null,
        });
    };

    const onFilterUpdate = (key: string) => (value: any) => {
        setFilterDetail((prev) => ({ ...prev, [key]: value }));
    };

    const columns: ColumnsType<StaffPackage> = [
        {
            title: "Status",
            dataIndex: "packageStatus",
            key: "packageStatus",
            sorter: false,
            ...createCustomFilter(
                StatusFilter,
                onFilterUpdate("status"),
                filterDetail.status
            ),
            render: (s) => PackageStatus[s],
        },
        {
            title: "ID",
            dataIndex: "friendlyId",
            key: "friendlyId",
            sorter: false,
            ...createCustomFilter(
                FriendlyIdFilter,
                onFilterUpdate("friendlyId"),
                filterDetail.friendlyId
            ),
            render: (id, rec) => <>
                <PackageDetailModal friendlyId={id} returnedCards={rec.returnedCards} />
                {rec.priority && <FireTwoTone twoToneColor={'#eb2f96'} style={{ marginLeft: '5px' }} title="Priority Processing" />}
            </>,
        },
        {
            title: "Customer",
            dataIndex: "accountId",
            key: "accountId",
            sorter: true,
            ...createCustomFilter(
                AccountSearch,
                onFilterUpdate("account"),
                filterDetail.account?.id
            ),
            render: (_, rec) => <CustomerDisplay {...rec} />,
        },
        {
            title: "Type",
            dataIndex: "packageType",
            key: "packageTypeId",
            sorter: false,
            ...createCustomFilter(
                PackageTypeSearch,
                onFilterUpdate("type"),
                filterDetail.type?.id
            ),
            render: (_, rec) => packageTypeDisplay(rec),
        },
        {
            title: "Amount",
            dataIndex: "packageAmount",
            key: "packageAmount",
            sorter: false,
            ...createCustomFilter(
                AmountFilter,
                onFilterUpdate("amount"),
                filterDetail.amount
            ),
            align: "right",
            render: (s) => PackageAmount[s],
        },
        {
            title: "Received Date",
            dataIndex: "receivedDate",
            key: "receivedDate",
            sorter: true,
            render: localizedDate,
        },
        {
            title: "Est. Upload Date",
            dataIndex: "estimatedUploadDate",
            key: "estimatedUploadDate",
            sorter: true,
            render: localizedDate,
        },
        {
            title: "Upload Date",
            dataIndex: "uploadDate",
            key: "uploadDate",
            sorter: true,
            render: localizedDate,
        },
        {
            title: "eBay Link",
            dataIndex: "ebayLink",
            key: "ebayLink",
            sorter: false,
            render: (link: string | null) =>
                link && (
                    <Button type="link" target="_blank" href={link}>
                        eBay Link
                    </Button>
                ),
        },
        {
            title: "",
            key: "edit",
            render: (_, rec) => (
                <PackageActions
                    package={rec}
                    onEdit={async () => {
                        await refetch();
                    }}
                />
            ),
        },
    ];

    const fetchCSV = useStaffPackagesCSV({
        filterBy,
        withinDays,
        sort,
    });

    const handleCSVExport = async () => {
        const csvData = await fetchCSV();
        const date = new Date().toISOString().split("T")[0];
        const filename = `all-packages-${date}.csv`;
        downloadAsCSV(csvData, filename);
    };

    return (
        <Box p={50}>
            <Row align="bottom">
                <Col span={6}>
                    <Box fontSize={36}>All Packages</Box>
                </Col>
                <Col span={10} offset={8} className="actions-container">
                    <TableActions
                        onDaysChanged={daysChanged}
                        isLoading={isLoading}
                        showFilter={false}
                        onExportCSV={handleCSVExport}
                    />
                </Col>
            </Row>
            <Row id="actions-and-filter">
                <Col span={4}>
                    <Button type="primary">
                        <Link to="/staff/packages/new">Add New Package</Link>
                    </Button>
                </Col>
                <Col span={20}>
                    <StaffPackagesFilter detail={filterDetail} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        id="staff-packages-table"
                        columns={columns}
                        dataSource={records}
                        rowKey={(r) => r.id}
                        onChange={tableChanged}
                        pagination={{
                            total: totalRecords,
                            current: page,
                            pageSize,
                            pageSizeOptions: PageSizeOptions,
                            showSizeChanger: true,
                            showQuickJumper: true,
                        }}
                        expandable={{
                            rowExpandable: (r) => r.batches.length > 0,
                            expandedRowRender: (r) => (
                                <BatchTable
                                    batches={r.batches}
                                    onSuccess={async () => await refetch()}
                                />
                            ),
                        }}
                        showSorterTooltip={false}
                        loading={isLoading}
                    />
                </Col>
            </Row>
        </Box>
    );
};

export default AllPackages;
