import { Input } from "antd";
import { FC } from "react";
import { dollarsToCents } from "util/helpers";

import { RuleObject } from "antd/lib/form";
import FormInput from "./FormInput";

// Stolen from ManualAdjustmentModal, but doesn't utilize the form instance
const amountValidator = (rule: RuleObject, amount: number) => {
    if (isNaN(amount)) {
        return Promise.reject(new Error("Not a valid amount!"));
    } else if (amount <= 0) {
        return Promise.reject(new Error("Amount must be greater than zero!"));
    } else if (rule.max && amount > rule.max) {
        return Promise.reject(new Error("Amount exceeds available balance!"));
    }
    return Promise.resolve();
};

const AmountFormItem: FC<{ max?: number }> = ({ max }) => (
    <FormInput
        label="Amount"
        name="amount"
        rules={[
            {
                required: true,
                validator: amountValidator,
                transform: dollarsToCents,
                type: "number",
                max
            }
        ]}
    >
        <Input prefix="$" suffix="USD" autoComplete="transaction-amount" />
    </FormInput>
);

export default AmountFormItem;
