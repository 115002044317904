import { SilentUser } from "./applicationUsers";
import { CardStatus } from "./cards";
import { PackageStatus } from "./packages";
import { TransactionType } from "./transactions";

// the `createBreakerHooks` function pushes these segments into query keys when 
// they have been opted-into, and the `useBreakerDataRefresh` hook creates callbacks
// that invalidate queries that contain these segments in the query key
export const TransactionQueryKeySegment = "tx";
export const CustomerQueryKeySegment = "ec";

export interface BreakerCustomer {
    id: string;
    userName: string;
    email: string;
    firstName: string;
    lastName: string;
    creditBalanceInCents: number;
    awaitingPaymentsInCents: number;
    activeListingsInCents: number;
    ebayLink: string | null;
    linkedToConsignmentCustomer: boolean;
}

export interface BreakerCustomerSearchResult {
    id: string;
    accountId: string;
    userName: string;
    email: string;
    firstName: string;
    lastName: string;
}

export interface BreakerSubmission {
    id: string;
    friendlyId: number;
    userId: string;
    userName: string;
    userEmail: string;
    packageStatus: PackageStatus;
    receivedDate: string;
    estimatedUploadDate: string | null;
    uploadDate: string | null;
    ebayLink: string | null;
}

export interface BreakerCard {
    id: string;
    userId: string;
    userName: string;
    userEmail: string;
    friendlyPackageId: number;
    title: string;
    status: CardStatus;
    listingDate: string;
    salePriceInCents: number;
    feesInCents: number;
    netInCents: number;
    frontImageUrl: string;
}

export interface BreakerTransaction {
    id: string;
    userId: string;
    accountId: string;
    userName: string;
    userEmail: string;
    transactionType: TransactionType,
    description: string;
    grossAmountInCents: number;
    feesInCents: number;
    netAmountInCents: number;
    transactionDate: string;
}

export interface CustomerBreaker {
    id: string;
    userName: string;
    email: string;
    creditBalanceInCents: number;
    awaitingPaymentsInCents: number;
    activeListingsInCents: number;
    ebayLink: string | null;
}

export interface CustomerBreakerCard {
    cardId: string;
    breakerUserId: string;
    breakerUserName: string;
    breakerEmail: string;
    title: string;
    status: string;
    listingDate: string;
    salePriceInCents: number;
    feesInCents: number;
    netInCents: number;
    frontImageUrl: string;
}

export interface CustomerBreakerStatus {
    breakerUserId: string;
    breakerUserName: string;
    breakerEmail: string;
    connected: boolean;
    silentUser: SilentUser | null;
}

export interface BreakerCustomerStatus {
    id: string;
    userName: string;
    linkedCustomerId: string | null;
    linkedCustomerUserName: string | null;
    linked: boolean;
}

export interface BreakerBalanceTransfer {
    userName: string;
    amountInCents: number;
}