import { useMutation } from "@tanstack/react-query";
import { useAuthenticatedRequestCreator } from "hooks/useRequests";
import { useBreakerDataRefresh } from "../createBreakerHooks";
import { BreakerTransactionForm } from "./UseCreditModal";

const useBreakerFundsTransfer = () => {
    const { onNewTransaction: onSuccess } = useBreakerDataRefresh();

    const request = useAuthenticatedRequestCreator<void, BreakerTransactionForm>(form => {
        const { amountInCents, customer, description } = form;

        return {
            method: "post",
            url: "/breaker/transactions/create",
            data: {
                customerId: customer.id,
                amountInCents,
                description
            }
        }
    }, [422, 404, 400]);

    const { mutateAsync: initiateTransfer, isLoading } = useMutation(request, {
        onSuccess
    });

    return { initiateTransfer, isLoading };
};

export default useBreakerFundsTransfer;