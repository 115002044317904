import "styles/cards.css";

import { LoadingOutlined } from "@ant-design/icons";
import { Box } from "@chakra-ui/react";
import { Col, Image, Row } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { FilterValue } from "antd/lib/table/interface";
import { useIdentity } from "context/auth";
import useCards, { useCardsCSV } from "hooks/useCards";
import { SportsCard } from "models/cards";
import { PageRequest } from "models/common";
import { useEffect, useState } from "react";
import downloadAsCSV from "util/downloadAsCSV";
import getDefaultPageRequest from "util/getDefaultPageRequest";
import { localizedDate } from "util/helpers";
import { createCustomFilter } from "util/table";
import Currency from "./Currency";
import TableActions from "./TableActions";
import { StaffCardFilterDetail } from "./staff/Cards/StaffCardsFilter";
import StatusFilter from "./staff/Cards/StatusFilter";
import FriendlyIdFilter from "./staff/Packages/FriendlyIdFilter";

const useColumns = () => {
    const [filterDetail, setFilterDetail] = useState<StaffCardFilterDetail>({});
    const onFilterUpdate = (key: string) => (value: any) => {
        setFilterDetail((prev) => ({ ...prev, [key]: value }));
    };
    const columns: ColumnsType<SportsCard> = [
        {
            title: "Package",
            dataIndex: "friendlyPackageId",
            key: "friendlyPackageId",
            sorter: false,
            ...createCustomFilter(
                FriendlyIdFilter,
                onFilterUpdate("friendlyPackageId"),
                filterDetail.friendlyPackageId
            ),
            render: (id) => (id === 0 ? "N/A" : id),
            responsive: ['lg']
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            ...createCustomFilter(
                StatusFilter,
                onFilterUpdate("status"),
                filterDetail.status
            ),
        },
        {
            title: "Image",
            dataIndex: "frontImageUrl",
            render: (url) => <Image
                src={url}
                width={30}
                placeholder={<LoadingOutlined />} />,
            responsive: ['xl']
        },
        {
            title: "Name",
            dataIndex: "title",
            key: "title",
            sorter: true,
        },
        {
            title: "Listing Date",
            dataIndex: "listingDate",
            key: "listingDate",
            sorter: true,
            render: localizedDate,
            responsive: ['xl']
        },
        {
            title: "Sale Price",
            dataIndex: "salePriceInCents",
            key: "salePrice",
            sorter: true,
            align: "right",
            render: (a) => <Currency cents={a} />,
            responsive: ['lg']
        },
        {
            title: "Fees",
            dataIndex: "feesInCents",
            key: "fees",
            align: "right",
            render: (a) => <Currency cents={-a} />,
            responsive: ['lg']
        },
        {
            title: "Net Amount",
            dataIndex: "netInCents",
            key: "net",
            align: "right",
            render: (a) => <Currency cents={a} />,
        },
    ];

    // Sync the query string filter with the filter detail so that the filter
    //  in the table UI represents the real state of the filter from the query string
    useEffect(() => {
        const { filterBy } = getDefaultPageRequest();
        Object.entries(filterBy ?? {}).forEach(([key, value]) => {
            // TODO: If generalizing this, we'll need to handle the case where
            //  the filter value is an array with multiple entries
            // NOTE: That ^ is part of the problem with generalizing this, the
            //  implementation is too bespoke for each type of filter
            const filterValue = (value as string[] | undefined)?.[0];
            // Change the first character to lowercase..
            const filterKey = key[0].toLowerCase() + key.slice(1);

            if (filterValue) {
                onFilterUpdate(filterKey)(filterValue);
            }
        });
    }, []);

    return { filterDetail, columns };
};

export const Cards = () => {
    const { accounts } = useIdentity();

    const [{ page, filter, filterBy, withinDays, sort }, setPageRequest] =
        useState<PageRequest>(getDefaultPageRequest());

    const { isLoading, data } = useCards(accounts[0], {
        page,
        filter,
        filterBy,
        withinDays,
        sort,
    });
    const { data: records, totalRecords, pageSize } = data || {};

    const filterChanged = (value: string) => {
        setPageRequest({ page: 1, filter: value, withinDays, sort, filterBy });
    };

    const daysChanged = (value: number) => {
        setPageRequest({ page: 1, filter, withinDays: value, sort, filterBy });
    };

    const tableChanged = (
        { current: newPage }: any, // pagination
        // filtering
        filters: Record<string, FilterValue | null>,
        { column, field, order }: any
    ) => {
        setPageRequest({
            page: newPage,
            filter,
            filterBy: filters,
            withinDays,
            sort: column ? [field, order === "ascend"] : null,
        });
    };

    const fetchTransactionsCSV = useCardsCSV(accounts[0], {
        filter,
        withinDays,
        filterBy,
        sort,
    });

    const handleCSVExport = async () => {
        const csvData = await fetchTransactionsCSV();
        const date = new Date().toISOString().split("T")[0];
        const filename = `cards-${date}.csv`;
        downloadAsCSV(csvData, filename);
    };

    const { columns } = useColumns();

    return (
        <Box p={30}>
            <Row align="bottom">
                <Col span={8}>
                    <Box fontSize={36}>All Cards</Box>
                    <dl>
                        <dt>Active</dt>
                        <dd>Live on eBay (current auction price shown and is not final)</dd>
                        <dt>Awaiting Payment</dt>
                        <dd>Sold but waiting on payment</dd>
                        <dt>Paid</dt>
                        <dd>Paid to customer</dd>
                        <dt>Returned</dt>
                        <dd>Returned due undisclosed defects in the listing, will be relisted</dd>
                        <dt>Shipped to Customer</dt>
                        <dd>The card has been shipped back to you, per your request</dd>
                    </dl>
                    <br />
                    <Box fontStyle='italic'>Card images are subject to removal 90 days after its auction has ended.</Box>
                </Col>
                <Col span={8} offset={7} className="actions-container">
                    <TableActions
                        onDaysChanged={daysChanged}
                        onFilterChanged={filterChanged}
                        isLoading={isLoading}
                        filterPlaceholder="Search Cards"
                        onExportCSV={handleCSVExport}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={records}
                        rowKey={(r) => r.cardId}
                        onChange={tableChanged}
                        pagination={{
                            total: totalRecords,
                            current: page,
                            pageSize,
                            showSizeChanger: false,
                            showQuickJumper: true,
                        }}
                        loading={isLoading}
                    />
                </Col>
            </Row>
        </Box>
    );
};
