import { useMutation, useQuery } from "@tanstack/react-query";
import { Alert, Form, Input, Modal, Select } from "antd";
import { useAuthenticatedRequest, useAuthenticatedRequestCreator } from "hooks/useRequests";
import { CustomerBreakerStatus } from "models/breaker";
import { useState } from "react";

interface BreakerLinkModalProps {
    open: boolean;
    onClose: (canceled: boolean) => Promise<void>;
}

interface BreakerConnectForm {
    breakerId: string;
    email: string;
}

const useBreakerStatus = () => {
    const getStatus = useAuthenticatedRequest<CustomerBreakerStatus[]>({
        method: "get",
        url: "/customer/breakers/status"
    });

    return useQuery(["customer", "breakers", "status"], getStatus);
}

const useInitiateBreakerLink = () => {
    const request = useAuthenticatedRequestCreator<void, BreakerConnectForm>(
        (data) => ({
            method: "post",
            url: "/customer/breakers/initiate-link",
            data
        }), [404]
    );

    const { mutateAsync: initiateLink, isLoading: isWorking } = useMutation(request);

    return { initiateLink, isWorking };
}

const BreakerLinkModal = ({ open, onClose }: BreakerLinkModalProps) => {
    const { data = [], isLoading } = useBreakerStatus();
    const [form] = Form.useForm<BreakerConnectForm>();
    const [error, setError] = useState(false);
    const [initiated, setInitiated] = useState(false);
    const { initiateLink, isWorking } = useInitiateBreakerLink();

    const submit = async () => {
        setError(false);

        if (initiated) {
            onClose(false);
            setInitiated(false);
            return;
        }

        const payload = await form.validateFields();

        try {
            await initiateLink(payload);
            setInitiated(true);
        }
        catch {
            setError(true);
        }
    }

    return <Modal
        title="Connect to a Breaker"
        open={open}
        onCancel={() => {
            onClose(true);
            setError(false)
        }}
        onOk={submit}
        cancelButtonProps={{ disabled: initiated || isLoading || isWorking }}
        confirmLoading={isLoading || isWorking}
        destroyOnClose
        maskClosable={false}
        keyboard={false}
        closable={false}
    >
        <Form
            form={form}
            disabled={initiated}
            layout="vertical"
            preserve={false}>
            <Form.Item name="breakerId" label="Available Breakers" rules={[{ required: true, message: "You must select a breaker!" }]}>
                <Select
                    options={data.map(x => ({
                        label: x.connected ? `${x.breakerUserName} - Already Connected` : x.breakerUserName,
                        value: x.breakerUserId,
                        disabled: x.connected
                    }))}
                />
            </Form.Item>

            <Form.Item name="email" label="What's the email address you gave the breaker?" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
        </Form>

        {error && <Alert
            type="error"
            showIcon
            message="That email wasn't found under the selected breaker!"
            description="Contact customer support if you feel this is an error."
        />}

        {initiated && <Alert
            type="success"
            showIcon
            message="Email Confirmation Sent!"
            description="Check the email at that address to complete the link."
        />}
    </Modal>
};

export default BreakerLinkModal;