import { Alert, Button, Divider, Form, Modal } from "antd";
import CustomerSelector from "components/CustomerSelector";
import useBulkCardUpdate, { BulkUpdateResult } from "hooks/useBulkCardUpdate";
import { ApplicationUser } from "models/applicationUsers";
import { PageRequest } from "models/common";
import React, { useState } from "react";

export interface BulkEditProps {
    visible: boolean;
    title?: string;
    affectedCount: number;
    onClose: () => void;
    onSubmit?: (form: { newCustomerId: string }) => Promise<BulkUpdateResult>;
    onSuccess?: () => void;
}

const BulkEditModal = (props: BulkEditProps) => {
    const [lockedCount, setLockedCount] = useState<number | undefined>();
    const [working, setWorking] = useState<boolean>(false);
    const [updateResult, setUpdateResult] = useState<
        BulkUpdateResult | undefined
    >(undefined);
    const [form] = Form.useForm();

    const closeModal = () => {
        props.onClose();
        setUpdateResult(undefined);
        setLockedCount(undefined);
    };

    const setCustomer = (customer: ApplicationUser | undefined) => {
        form.setFieldsValue({ newCustomerId: customer?.id });
    };

    const submit = async (form: { newCustomerId: string }) => {
        setWorking(true);
        let result: BulkUpdateResult | undefined;

        try {
            // locking in the affected count so it doesn't change
            // if the update causes the filter result to change.
            setLockedCount(props.affectedCount);
            result = props.onSubmit && (await props.onSubmit(form));
        } finally {
            setWorking(false);
        }

        if (result) {
            setUpdateResult(result);
            props.onSuccess && props.onSuccess();
        }
    };

    const showResponseAlert = (updateCount: number) => {
        if (updateCount === 0) {
            return (
                <Alert
                    className="bold-font"
                    message="No records updated, you may have set the same customer!"
                    type="warning"
                    showIcon
                />
            );
        } else {
            return (
                <Alert
                    className="bold-font"
                    message={`${updateCount} records updated successfully!`}
                    type="success"
                    showIcon
                />
            );
        }
    };

    return (
        <Modal
            className="card-edit-modal"
            title={props.title}
            open={props.visible}
            closable={false}
            keyboard={false}
            maskClosable={false}
            destroyOnClose
            confirmLoading={working}
            cancelButtonProps={{ disabled: working }}
            okText={working ? "Saving..." : "Update"}
            okButtonProps={{ form: "bulk-edit-form", htmlType: "submit" }}
            onCancel={closeModal}
            footer={
                updateResult ? (
                    <Button type="primary" onClick={closeModal}>
                        OK
                    </Button>
                ) : undefined
            }
        >
            <Form
                id="bulk-edit-form"
                form={form}
                layout="vertical"
                disabled={working}
                onFinish={submit}
            >
                <Form.Item
                    label="Customer"
                    name="newCustomerId"
                    rules={[
                        {
                            required: true,
                            message: "You must select a customer!",
                        },
                    ]}
                >
                    <CustomerSelector
                        autoFocus
                        disabled={updateResult !== undefined}
                        onClear={() => setCustomer(undefined)}
                        onSelected={setCustomer}
                    />
                </Form.Item>
            </Form>
            <Alert
                className="bold-font"
                message={`This update can affect up to ${(
                    lockedCount || props.affectedCount
                ).toLocaleString()} cards!`}
                type="info"
                showIcon
            />
            {updateResult && (
                <>
                    <Divider />
                    {showResponseAlert(updateResult.updateCount)}
                </>
            )}
        </Modal>
    );
};

export const BulkEditSelectionModal = (
    props: BulkEditProps & { cardIds: React.Key[] }
) => {
    const { updateBySelection } = useBulkCardUpdate();

    const onSubmit = async (form: { newCustomerId: string }) => {
        return await updateBySelection({ ...form, cardIds: props.cardIds });
    };

    return (
        <BulkEditModal
            {...props}
            title="Bulk Edit Selected Cards"
            onSubmit={onSubmit}
        />
    );
};

export const BulkEditFilterModal = (
    props: BulkEditProps & { pageRequest: PageRequest }
) => {
    const { updateByFilter } = useBulkCardUpdate();

    const onSubmit = async (form: { newCustomerId: string }) => {
        return await updateByFilter({
            ...form,
            pageRequest: props.pageRequest,
        });
    };

    return (
        <BulkEditModal
            {...props}
            title="Bulk Edit Filtered Cards"
            onSubmit={onSubmit}
        />
    );
};
