import { blue } from '@ant-design/colors';
import { Col, Divider, Row } from "antd";
import { BreakerCustomer } from 'models/breaker';

const CustomerBanner = ({ firstName, lastName, userName, email }: BreakerCustomer) => {
    return <Row>
        <Col span={24} style={{
            justifyItems: 'center',
            backgroundColor: blue[0],
            margin: '10px 0',
            padding: '5px 0',
            border: `1px solid ${blue[1]}`
        }}>
            <div style={{ fontSize: '28px' }}>
                {firstName} {lastName}
            </div>
            <div style={{ fontSize: '18px', color: 'var(--dcs-gray)' }}>
                {userName} <Divider type="vertical" /> {email}
            </div>
        </Col>
    </Row>
};

export default CustomerBanner;