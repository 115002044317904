import { Alert, Modal } from "antd";
import { useCsvExport } from "components/CsvExport";
import { useState } from "react";
import { useBreakerDataRefresh } from "./createBreakerHooks";

interface BulkTransferModalProps {
    visible: boolean;
    onClose: () => void;
}

const bulkTransferRequest = {
    method: "post",
    url: "/breaker/users/bulk-balance-transfer?format=csv"
};

const BulkTransferModal = ({ visible, onClose }: BulkTransferModalProps) => {
    const transferBalances = useCsvExport(bulkTransferRequest, "bulk-balance-transfer");
    const { onNewTransaction } = useBreakerDataRefresh();
    const [loading, setLoading] = useState(false);

    const confirmed = async () => {
        try {
            setLoading(true);
            await transferBalances();
            await onNewTransaction();
            onClose();
        } finally {
            setLoading(false);
        }
    }

    return <Modal
        title="Bulk Credit Balance Transfer"
        confirmLoading={loading}
        open={visible}
        onCancel={onClose}
        onOk={confirmed}
        closable={false}
        keyboard={false}
        maskClosable={false}
        destroyOnClose
        cancelButtonProps={{ disabled: loading }}
        okText="Yes!"
    >
        <Alert type="warning"
            showIcon
            description="This will transfer all customer credit balances into your available balance & initiate a CSV download of the amounts withdrawn from each customer. Do you want to proceed?"
        />
    </Modal>;
};

export default BulkTransferModal;