import { Col, Input, Radio, Row } from "antd";
import Form, { FormInstance, RuleObject } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import { AdjustmentType } from "models/common";
import { dollarsToCents } from "util/helpers";
import { BreakerTransactionForm } from "./UseCreditModal";

const DetailsStep = (props: {
    form: FormInstance<BreakerTransactionForm>;
    active: boolean;
}) => {
    const { form, active } = props;

    if (!active) {
        return <></>;
    }

    const amountValidator = (_: RuleObject, price: number) => {
        if (isNaN(price)) {
            return Promise.reject(new Error("Not a valid amount!"));
        } else if (price <= 0) {
            return Promise.reject(
                new Error("Amount must be greater than zero!")
            );
        }
        form.setFieldsValue({ amountInCents: price });
        return Promise.resolve();
    };

    return (
        <Form form={form} initialValues={{ amount: "" }} layout="vertical">
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item
                        label="Amount"
                        name="amount"
                        rules={[
                            {
                                required: true,
                                validator: amountValidator,
                                transform: dollarsToCents
                            }
                        ]}
                    >
                        <Input prefix="$" suffix="USD" />
                    </Form.Item>
                    <Form.Item hidden name="amountInCents">
                        <Input type="hidden" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Type"
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: "You must select credit or debit!"
                            }
                        ]}
                    >
                        <Radio.Group id="adjustment-type">
                            <Radio.Button
                                value={AdjustmentType.Credit}
                                className="adj-credit"
                            >
                                Credit
                            </Radio.Button>
                            <Radio.Button
                                value={AdjustmentType.Debit}
                                className="adj-debit"
                            >
                                Debit
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                label="Description"
                name="description"
                rules={[
                    {
                        required: true,
                        message:
                            "You must specify a reason for the transaction!"
                    }
                ]}
            >
                <TextArea
                    rows={2}
                    placeholder="A brief description of the reason for this transaction"
                />
            </Form.Item>
        </Form>
    );
};

export default DetailsStep;