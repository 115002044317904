import { AxiosRequestConfig } from "axios";
import { AccountType, CustomerAccount } from "models/accountSummary";
import { ApplicationUser, SilentUser } from "models/applicationUsers";
import { JsonPatchDocument, PageRequest } from "models/common";
import Moment from "moment";
import { DefaultPageRequest } from "./getDefaultPageRequest";

const USDCurrencyOptions = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
}).resolvedOptions();

/**
 * Converts a whole number representing a value in cents to a USD formatted currency string
 * @param {number} cents The value to convert to dollars
 * @param {boolean} includeSymbol Whether or not to include the currency symbol (defaults to true)
 * @param {boolean} useGrouping Whether or not to include grouping symbols (commas, etc)
 * @returns {string} Dollars and cents, formatted as USD currency
 * @example centsToDollars(987); // returns '$9.87'
 * centsToDollars(4380, false); // returns '43.80'
 */
export const centsToDollars = (
    cents: number,
    includeSymbol: boolean = true,
    useGrouping: boolean = true
): string => {
    const dollars = cents / 100;
    const { locale } = USDCurrencyOptions;

    if (includeSymbol) {
        return dollars.toLocaleString(locale, USDCurrencyOptions);
    }

    return dollars.toLocaleString(locale, {
        minimumFractionDigits: USDCurrencyOptions.minimumFractionDigits,
        useGrouping,
    });
};

/**
 * Converts a USD formatted currency string to a whole number representing a value in cents.
 * If the input is invalid, returns NaN.
 * @param {string} dollars The formatted currency string, with or without a leading symbol
 * @returns {number} The dollar amount in cents
 * @example dollarsToCents('$10.00'); // returns 1000
 * dollarsToCents('1,025.81'); // returns 102581
 * dollarsToCents('1,3,0.4'); // returns NaN
 */
export const dollarsToCents = (dollars: string): number => {
    const valid = !!dollars.match(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/
    );

    if (!valid) {
        return NaN;
    }

    // get rid of non-digits except for the decimal point
    const value = Number(dollars.replace(/[^0-9.]+/g, ""));

    // rounding to deal with JS floating point precision
    return Math.round(value * 100);
};

/**
 * Formats a ISO-8601 date string as a localized date
 * @param {string} date The ISO-8601 date as a string
 * @returns {string} The date local to the user
 */
export const localizedDate = (date: string | null | undefined): string => {
    if (date === null || date === undefined) {
        return "";
    }

    return Moment(date).format("L");
};

/**
 * Formats a ISO-8601 date string as a localized date with time
 * @param {string} date The ISO-8601 date as a string
 * @returns {string} The date local to the user
 */
export const localizedDateAndTime = (date: string | null | undefined): string => {
    if (date === null || date === undefined) {
        return "";
    }

    return Moment(date).format("L LT");
};
/**
 * Capitalizes the first character in a string, suitable for transforming sort keys into values
 * the backend expects
 * @param {string} str The string to capitalize
 * @returns {string} capitalized result
 * @example capitalize('columnName'); // returns 'ColumnName'
 */
export const capitalize = (str: string): string => {
    return str.replace(/^./, (m) => m.toUpperCase());
};

/**
 * Converts a PageRequest to a URLSearchParams instance. Properties not set will be excluded
 * @param {PageRequest} pageRequest page request to convert
 * @returns {URLSearchParams} URLSearchParams
 */
export const pageRequestToUrlSearchParams = (
    pageRequest: PageRequest
): URLSearchParams => {
    const { page, filter, withinDays, sort, filterBy, format, pageSize } =
        pageRequest;
    const options = new URLSearchParams({ page: page.toString() });

    if (pageSize) {
        options.append("pageSize", pageSize.toString());
    }

    if (filter) {
        options.append("filter", filter);
    }

    if (withinDays > 0) {
        options.append("withinDays", withinDays.toString());
    }

    if (sort) {
        options.append("sortKey", capitalize(sort[0]));
        options.append("sortAsc", sort[1].toString());
    }

    if (format) {
        options.append("format", format);
    }

    for (let key in filterBy) {
        filterBy[key]?.forEach((val) =>
            options.append(`filterBy[${capitalize(key)}]`, val.toString())
        );
    }

    return options;
};

/**
 * Returns a string with the format of 'Firstname Lastname (Email)' for a given customer
 * @param {ApplicationUser} user
 * @returns  {string} the customer display name
 */
export const toDisplayName = (
    user: ApplicationUser | null | undefined
): string => {
    if (!user) {
        return "";
    }

    return `${user.firstName} ${user.lastName} (${user.email})`;
};

export type FormatFunc = (n: number) => string;

export const valueOrUnknown = (
    val: number | undefined,
    formatter: FormatFunc
): string => {
    if (val === undefined) {
        return "???";
    }

    return formatter(val);
};

/**
 * Converts a role name to a display name (ex. admin --> Admin)
 */
export const roleNameToDisplayName = (role: string): string => {
    const nameToDisplayMap: Record<string, string | undefined> = {
        admin: "Admin",
        staff: "Staff",
        user: "User",
        breaker: "Breaker",
        typer: "Typer"
    };
    return nameToDisplayMap[role] ?? role;
};

/**
 * Converts a given string to A Title Cased String
 * @param str 
 * @returns 
 */
export const toTitleCase = (str: string) => {
    const tokens = str.toLowerCase().split(' ');
    for (var i = 0; i < tokens.length; i++) {
        tokens[i] = tokens[i].charAt(0).toUpperCase() + tokens[i].slice(1);
    }
    return tokens.join(' ');
};

/**
 * Converts a Partial<T> into a JsonPatchDocument, but the only operation supported is 'replace'.
 * @param rec The Partial<T> to generate a document for
 * @param transform An object that describes key mapping changes when generating the document (i.e. { 'myProp': 'property' } would create a patch operation with a path of 'property' with the value of rec.myProp)
 * @returns 
 */
export const toPatchDoc = <T extends object>(
    rec: Partial<T>,
    transform: Partial<Record<keyof T, string>> = {}
): JsonPatchDocument => {
    const data = [];

    let key: keyof T;
    for (key in rec) {
        const path = (transform[key] ?? key) as string;
        const value = rec[key];

        data.push({ op: 'replace', path, value });
    }

    return data;
};

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export const groupBy = <K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> => {
    const map = new Map<K, Array<V>>();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
};

/**
 * Returns a formatted string displaying customer information based on the account type
 * @param account The CustomerAccount to display
 * @returns 
 */
export const customerAccountDisplay = (account: CustomerAccount) => {
    if (account.type === AccountType.Consignment) {
        return `${account.firstName} ${account.lastName}`
    } else {
        return `${account.firstName} ${account.lastName} via ${account.ownerUserName}`
    }
}

/**
 * Returns a GET request config suitable for use with useAuthenticatedRequest that expands the page request
 * into query string parameters for the given url. Any missing page request parameters will be populated by
 * the values in the DefaultPageRequest object
 * @param url 
 * @param pageRequest 
 * @returns 
 */
export const getPageRequest = (url: string, pageRequest: Partial<PageRequest>): AxiosRequestConfig => {
    const options = pageRequestToUrlSearchParams({ ...DefaultPageRequest, ...pageRequest });

    return {
        method: 'get',
        url: `${url}?${options}`
    };
};

export const isNewSilentUser = (user: SilentUser) => user.email.endsWith("unknown");