import { LoadingOutlined } from "@ant-design/icons";
import { Image } from "antd";
import { ColumnsType } from "antd/lib/table";
import Currency from "components/Currency";
import { BreakerCustomerSearch } from "components/EntityTableSearch";
import MultiValueDisplay from "components/MultiValueDisplay";
import CardTitleSearch from "components/staff/Cards/CardTitleSearch";
import StatusFilter from "components/staff/Cards/StatusFilter";
import FriendlyIdFilter from "components/staff/Packages/FriendlyIdFilter";
import { BreakerCard, BreakerCustomerSearchResult } from "models/breaker";
import { CardStatus } from "models/cards";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { localizedDate } from "util/helpers";
import { createCustomFilter } from "util/table";
import BreakerTable from "./BreakerTable";
import { createBreakerHooks } from "./createBreakerHooks";

const hooks = createBreakerHooks<BreakerCard>('/breaker/cards', {
    refreshOnCustomerEdit: true
});

const cardStatusArray = Object.values(CardStatus);
const validStatusFilterValues = cardStatusArray.map(x => x.toLowerCase());
type CardStatusFilter = typeof validStatusFilterValues[number];

const initialStatusFilter = (requestedFilter: string | null): CardStatus | undefined => {
    const idx = validStatusFilterValues.indexOf(requestedFilter as CardStatusFilter);
    if (idx === -1) {
        return undefined;
    }

    return cardStatusArray[idx];
}

const BreakerCards = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const queryFilters = {
        status: initialStatusFilter(searchParams.get('f'))
    };

    const [filter, setFilter] = useState<{
        customer?: BreakerCustomerSearchResult;
        packageId?: number;
        title?: string;
        status?: CardStatus;
    }>(() => ({
        status: queryFilters.status
    }));

    const initialPageRequest = {
        filterBy: queryFilters.status
            ? { status: [queryFilters.status] }
            : undefined
    };

    const deleteQueryFilters = () => {
        searchParams.delete('f');
        setSearchParams(searchParams);
    };

    const columns: ColumnsType<BreakerCard> = [
        {
            title: <MultiValueDisplay title="User Name" subTitle="Email" titleColor="inherit" subTitleColor="inherit" />,
            dataIndex: "userName",
            key: "userId",
            ...createCustomFilter(
                BreakerCustomerSearch,
                customer => setFilter({ customer }),
                filter.customer?.id
            ),
            render: (userName, rec) => (
                <MultiValueDisplay
                    title={userName}
                    subTitle={rec.userEmail}
                />
            ),
        },
        {
            title: "Package",
            dataIndex: "friendlyPackageId",
            key: "friendlyPackageId",
            sorter: false,
            ...createCustomFilter(
                FriendlyIdFilter,
                packageId => setFilter({ packageId }),
                filter.packageId
            ),
            render: (id) => (id === 0 ? "N/A" : id)
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            ...createCustomFilter(
                StatusFilter,
                status => setFilter({ status }),
                filter.status
            ),
        },
        {
            title: "Image",
            dataIndex: "frontImageUrl",
            render: (url) => <Image
                src={url}
                width={30}
                placeholder={<LoadingOutlined />} />
        },
        {
            title: "Name",
            dataIndex: "title",
            key: "title",
            sorter: true,
            ...createCustomFilter(
                CardTitleSearch,
                title => setFilter({ title }),
                filter.title
            )
        },
        {
            title: "Listing Date",
            dataIndex: "listingDate",
            key: "listingDate",
            sorter: true,
            render: localizedDate
        },
        {
            title: "Sale Price",
            dataIndex: "salePriceInCents",
            key: "salePrice",
            sorter: true,
            align: "right",
            render: (a) => <Currency cents={a} />
        },
        {
            title: "Fees",
            dataIndex: "feesInCents",
            key: "fees",
            align: "right",
            render: (a) => <Currency cents={-a} />
        },
        {
            title: "Net Amount",
            dataIndex: "netInCents",
            key: "net",
            align: "right",
            render: (a) => <Currency cents={a} />
        },
    ];

    return <BreakerTable
        hooks={hooks}
        columns={columns}
        noDataText="No cards have been listed on eBay!"
        initialPageRequest={initialPageRequest}
        onTableChanged={deleteQueryFilters}
    />;
}

export default BreakerCards;