import { Stack, VStack } from "@chakra-ui/layout";
import { useQuery } from "@tanstack/react-query";
import { BreakerRoutes } from "appRoutePaths";
import RequestPayoutModal from "components/customer/Payouts/RequestPayoutModal/RequestPayoutModal";
import { CustomerInfoBox } from "components/CustomerInfoBox";
import FullScreenSpinner from "components/FullScreenSpinner";
import { WaitTimesBanner } from "components/WaitTimes";
import { useAuthenticatedRequest } from "hooks/useRequests";
import { AccountType, BreakerAccountSummary } from "models/accountSummary";
import { TransactionQueryKeySegment } from "models/breaker";
import { CardStatus } from "models/cards";
import { Suspense, useState } from "react";
import "styles/customer_info.css";
import { centsToDollars, valueOrUnknown } from "util/helpers";
import BulkTransferModal from "./BulkTransferModal";

const useBreakerAccountSummary = () => {
    const getAccountSummary = useAuthenticatedRequest<BreakerAccountSummary>({
        method: "get",
        url: "/breaker/account-summary",
    });

    return useQuery(["breaker", "account-summary", TransactionQueryKeySegment], getAccountSummary);
};

export const BreakerInfoBanner = () => {
    const { isLoading, data } = useBreakerAccountSummary();
    const [payoutVisible, setPayoutVisible] = useState(false);
    const [bulkTransferVisible, setBulkTransferVisible] = useState(false);

    const availableBalance = valueOrUnknown(
        data?.availableBalanceInCents,
        centsToDollars
    );
    const customerCredit = valueOrUnknown(
        data?.customerCreditInCents,
        centsToDollars
    );
    const moneyOnTheWay = valueOrUnknown(
        data?.moneyOnTheWayInCents,
        centsToDollars
    );
    const activeListings = valueOrUnknown(
        data?.activeListingsInCents,
        centsToDollars
    );

    return <>
        <VStack width="100%" bg="rgba(from var(--dcs-dark-green) r g b / 0.7)" color="var(--dcs-black)" borderBottom="2px solid white">
            <Stack direction="row">
                <WaitTimesBanner />
            </Stack>
            <Stack
                direction={{
                    base: "column",
                    lg: "row",
                }}
                width="100%"
                py="4"
                px="24"
                spacing="4"
            >
                <CustomerInfoBox
                    isLoading={isLoading}
                    title="Available Balance"
                    subTitle="Amount of money you can cash out."
                    focusText={availableBalance}
                    linkText="Request Payout"
                    onLinkClick={() => setPayoutVisible(true)}
                />
                <CustomerInfoBox
                    isLoading={isLoading}
                    title="Customer Credit"
                    subTitle="Total of customer credit balances"
                    focusText={customerCredit}
                    linkText="Bulk Balance Transfer"
                    onLinkClick={() => setBulkTransferVisible(true)}
                />
                <CustomerInfoBox
                    isLoading={isLoading}
                    title="Awaiting Payment"
                    subTitle="Total of customer cards awaiting payment (fees included)"
                    focusText={moneyOnTheWay}
                    linkUrl={BreakerRoutes.cardsTable.url(CardStatus.AwaitingPayment)}
                    linkText="See Cards Awaiting Payment"
                    forceReload
                />
                <CustomerInfoBox
                    isLoading={isLoading}
                    title="Active Listings"
                    subTitle="Total of current bids on customer cards (fees included)"
                    focusText={activeListings}
                    linkUrl={BreakerRoutes.cardsTable.url(CardStatus.Active)}
                    linkText={"See All Listed"}
                    forceReload
                />
            </Stack>
        </VStack>
        <Suspense fallback={<FullScreenSpinner />} >
            <RequestPayoutModal
                visible={payoutVisible}
                onClose={() => setPayoutVisible(false)}
                sourceAccount={AccountType.Holding}
            />
            <BulkTransferModal
                visible={bulkTransferVisible}
                onClose={() => setBulkTransferVisible(false)}
            />
        </Suspense>
    </>;
};
