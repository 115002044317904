import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { Alert, Button, Form, Input, Select } from "antd";
import { StaffRoutes } from "appRoutePaths";
import CustomerSelector from "components/CustomerSelector";
import SilentUserSelector from "components/SilentUserSelector";
import { Roles } from "context/auth";
import useAvailablePackageTypes from "hooks/useAvailablePackageTypes";
import { ApplicationUser, SilentUser } from "models/applicationUsers";
import { PackageTypeOption } from "models/common";
import { PackageAmount, PackageType, StaffPackage } from "models/packages";
import { PackageQRCodeLink } from "pages/staff/NewPackage";
import { useState } from "react";
import { Link } from "react-router-dom";
import "styles/staff/new_package.css";
import packageAmountOptions from "./packageAmountOptions";

const { Option } = Select;

export interface NewPackageFormData {
    customer: ApplicationUser;
    amount: PackageAmount;
    packageType: PackageType;
    silentUser?: SilentUser;
}

const NewPackageForm = (props: {
    prevPackage?: StaffPackage;
    disabled: boolean;
    onSubmit: (form: NewPackageFormData) => void;
    packageType?: PackageType,
    customer?: ApplicationUser
}) => {
    const [form] = Form.useForm<NewPackageFormData>();
    const [currentPkgType, setCurrentPkgType] = useState<
        PackageType | undefined
    >(props.packageType);
    const [roleFilter, setRoleFilter] = useState<string[] | undefined>();
    const { data: types } = useAvailablePackageTypes();
    const [defaultCustomer, setDefaultCustomer] = useState(props.customer);
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState<string>();

    const selectedCustomer = Form.useWatch('customer', form);

    const onPackageTypeChange = (_: string, option: PackageTypeOption | PackageTypeOption[]) => {
        const opt = option as PackageTypeOption;
        setCurrentPkgType(opt.packageType);
        setDefaultCustomer(undefined);

        if (opt.packageType.supportSilentAccounts) {
            setRoleFilter([Roles.Breaker]);
        } else {
            setRoleFilter(undefined);
        }

        form.resetFields(['customer', 'silentUser']);
        form.setFieldsValue({
            packageType: opt.packageType,
            amount: opt.packageType.usesAmounts
                ? undefined
                : PackageAmount.NotApplicable
        });
    };

    const userCreationResult = (result: SilentUser | string) => {
        setIsSaving(false);

        // error message
        if (typeof result === "string") {
            setError(result);
        } else {
            setError(undefined);
        }
    }

    const filteredPackageAmounts = currentPkgType?.usesAmounts
        ? packageAmountOptions.filter(o => o.value !== PackageAmount.NotApplicable)
        : packageAmountOptions.filter(o => o.value === PackageAmount.NotApplicable);

    const filteredAmountOptions = filteredPackageAmounts.map((o) => (
        <Option key={o.value} value={o.value}>
            {o.label}
        </Option>
    ));

    return (
        <Form
            id="new-pkg"
            form={form}
            initialValues={{
                customer: props.customer,
                packageType: props.packageType,
                type: props.packageType?.id
            }}
            disabled={isSaving || props.disabled}
            onFinish={props.onSubmit}
            layout="vertical"
        >
            <VStack align="start" minW="30rem" maxW="35rem" w="100%">
                <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
                    <Box
                        flex={1}
                        pr={6}
                        as={Form.Item}
                        label="Package Type"
                        rules={[
                            {
                                required: true,
                                message: "Package type is required!",
                            },
                        ]}
                        name="type"
                    >
                        <Select
                            disabled={types?.length === 0}
                            onChange={onPackageTypeChange}
                            options={types?.map(t =>
                                ({ label: t.internalName, value: t.id, packageType: t }))
                            } />
                    </Box>
                    <Box hidden as={Form.Item} name="packageType">
                        <Input hidden />
                    </Box>
                    <Box
                        flex={1}
                        as={Form.Item}
                        label="Package Amount"
                        name="amount"
                        rules={[
                            {
                                required: true,
                                message: "Package amount is required!",
                            },
                        ]}
                    >
                        <Select>{filteredAmountOptions}</Select>
                    </Box>
                </Flex>
                <Box
                    as={Form.Item}
                    minWidth="30rem"
                    label={currentPkgType?.supportSilentAccounts ? "Breaker Name" : "Customer Name"}
                    help={currentPkgType?.supportSilentAccounts
                        ? <Alert message="Limited to customers in specific role(s)" type="info" showIcon />
                        : undefined}
                    name="customer"
                    rules={[
                        {
                            required: true,
                            message: "You must select a customer!",
                        },
                    ]}
                >
                    <CustomerSelector
                        initialEntity={defaultCustomer}
                        roles={roleFilter}
                        onSelected={(customer) => {
                            form.setFieldsValue({ customer });
                        }}
                        onClear={() => {
                            form.setFieldsValue({ customer: undefined });
                        }}
                    />
                </Box>
                {currentPkgType?.supportSilentAccounts && <Box
                    as={Form.Item}
                    pt={4}
                    minWidth="30rem"
                    label="Customer Name"
                    name="silentUser"
                    rules={[
                        {
                            required: true,
                            message: "You must select an account!",
                        },
                    ]}
                    help={error && <Alert showIcon type="error" message={error} />}
                >
                    <SilentUserSelector
                        allowUserCreation
                        ownerId={selectedCustomer?.id}
                        placeholder={(selectedCustomer === null || selectedCustomer === undefined ? "Select a breaker first" : undefined)}
                        disabled={!selectedCustomer}
                        onSelected={(silentUser) => {
                            form.setFieldsValue({ silentUser });
                        }}
                        onClear={() => {
                            form.setFieldsValue({ silentUser: undefined });
                        }}
                        onCreating={() => {
                            setError(undefined);
                            setIsSaving(true);
                        }}
                        onCreated={userCreationResult}
                        onError={userCreationResult}
                    />
                </Box>}
                <HStack spacing={10} w='100%' display='flex' justifyContent='flex-end'>
                    {props.prevPackage && <div style={{ flex: 1 }}>
                        <PackageQRCodeLink id={props.prevPackage.id} text="Prev. QR Code" />
                    </div>}
                    <div>
                        <Button size="large" type="link">
                            <Link to={StaffRoutes.packages.url}>Cancel</Link>
                        </Button>
                        <Button size="large" type="primary" htmlType="submit">
                            Create
                        </Button>
                    </div>
                </HStack>
            </VStack>
        </Form>
    );
};

export default NewPackageForm;
