import { AccountType } from "./accountSummary";

export enum PayoutRequestMethod {
    Unknown = 0, // represents invalid state
    ACH = 1,
    Check = 2,
    Paypal = 3,
    Wire = 4,
    Other = 5, // for imported legacy payment methods no longer supported. Not valid.
    InternationalWire = 6 // Same as wire, only w/o the $10k USD minimum
}

// A subset of the enum that's just the ones we can handle in the form
export type ValidPaymentMethods = Exclude<
    PayoutRequestMethod,
    PayoutRequestMethod.Unknown | PayoutRequestMethod.Other
>;

export const ValidPaymentMethodLabels = {
    [PayoutRequestMethod.ACH]: "ACH",
    [PayoutRequestMethod.Wire]: "Wire",
    [PayoutRequestMethod.InternationalWire]: "Intl. Wire",
    [PayoutRequestMethod.Check]: "Check",
    [PayoutRequestMethod.Paypal]: "PayPal"
} as const;

export const PayoutFeeLabels = {
    [PayoutRequestMethod.ACH]: "Transfer Fee",
    [PayoutRequestMethod.Wire]: "Transfer Fee",
    [PayoutRequestMethod.InternationalWire]: "Transfer Fee",
    [PayoutRequestMethod.Check]: "Shipping Fee",
    [PayoutRequestMethod.Paypal]: "PayPal Fee"
} as const;

export enum PayoutRequestStatus {
    Unknown = 0, // represents invalid state
    Cancelled = 1,
    Complete = 2,
    Pending = 3,
    InfoNeeded = 4,
    Submitted = 5
}

export const PayoutRequestStatusLabels = {
    [PayoutRequestStatus.Cancelled]: "cancelled",
    [PayoutRequestStatus.Complete]: "complete",
    [PayoutRequestStatus.Pending]: "pending",
    [PayoutRequestStatus.Unknown]: "unknown",
    [PayoutRequestStatus.InfoNeeded]: "infoneeded",
    [PayoutRequestStatus.Submitted]: "submitted"
} as const;

export interface LegacyACHPayoutMethodDetails {
    nameOnAccount: string;
    accountDigits: string;
}

export interface ChaseACHPayoutMethodDetails {
    externalAccountId: string;
    organization: string;
    accountNumber: string;
    routingNumber: string;
    requiresApproval: boolean;
    payableNumber: string | null;
    confirmationNumber: string | null;
}

export enum CheckShippingMethods {
    Standard = "standard",
    Overnight = "overnight",
}

export interface CheckPayoutMethodDetails {
    payee: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    shippingMethod: CheckShippingMethods;
    trackingNumber: string | null;
    labelUrl: string | null;
    trackingUrl: string | null;
}

export interface PaypalPayoutMethodDetails {
    email: string;
}

export interface WirePayoutMethodDetails {
    nameOnAccount: string;
    accountDigits: string;
}

export interface InternationalWirePayoutMethodDetails {
    nameOnAccount: string;
    accountDigits: string;
}

export type PayoutMethodDetails =
    | LegacyACHPayoutMethodDetails
    | ChaseACHPayoutMethodDetails
    | CheckPayoutMethodDetails
    | PaypalPayoutMethodDetails
    | WirePayoutMethodDetails
    | InternationalWirePayoutMethodDetails;

export type CreatePayoutRequestPayload = {
    method: ValidPaymentMethods;
    amountInCents: number;
    customerId?: string;
    accountId: string | null;

    // The details depend on the method chosen, though that sounds really hard to bake into typescript lol
    details: PayoutMethodDetails;

    notes?: string;
};

export enum CompletedPaymentType {
    Unknown = 0,
    RTP = 1,
    SameDay = 2,
    NextDay = 3
}

export const CompletedPaymentTypeNames = {
    [CompletedPaymentType.Unknown]: "",
    [CompletedPaymentType.RTP]: "RTP",
    [CompletedPaymentType.SameDay]: "Same Day",
    [CompletedPaymentType.NextDay]: "Next Day"
} as const;

export type PayoutRequest = {
    id: string;
    accountId: string;
    amount: number;
    currency: string;
    status: PayoutRequestStatus;
    method: PayoutRequestMethod;
    details: PayoutMethodDetails;
    grossAmountInCents: number;
    feeInCents: number;
    netAmountInCents: number;
    notes: string;
    requestDate: string;
    approvedByUserEmail?: string;
    approvedDate?: string;
    cancelledByUserEmail?: string;
    cancelledDate?: string;
    cancelledReason?: string;
    cancelledBySystem: boolean;
    requestingUserId?: string;
    requestingUserFirstName?: string;
    requestingUserLastName?: string;
    requestingUserEmail?: string;
    submissionDate?: string;
    paymentType?: CompletedPaymentType;
    progress?: string;
};

export type PayoutRequestSourceAccountType = AccountType.Consignment | AccountType.Holding;