import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    ReactElement,
    ReactNode,
    SetStateAction,
    useContext,
    useState
} from "react";

interface BreakerPortalContent {
    content: ReactNode,
    setContent: Dispatch<SetStateAction<ReactElement>>
}

export const BreakerPortalContext = createContext<BreakerPortalContent>({
    content: <></>,
    setContent: () => { }
});

export const useBreakerPortalContext = () => {
    const context = useContext(BreakerPortalContext);
    if (!context) {
        throw new Error('useBreakerPortalContext must be used within a BreakerPortalContextProvider');
    }

    return context;
}

export const BreakerPortalContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [content, setContent] = useState(<></>);

    return <BreakerPortalContext.Provider value={{ content, setContent }}>
        {children}
    </BreakerPortalContext.Provider>
}