import { ReactNode } from "react";

const MultiValueDisplay = (props: {
    title: string;
    subTitle: string,
    opacity?: number,
    titleColor?: string,
    subTitleColor?: string
    status?: ReactNode
}) => {
    return (
        <div style={{ opacity: props.opacity || '1' }}>
            <span style={{ fontWeight: '500', display: 'flex', opacity: '1', color: props.titleColor ?? 'var(--dcs-dark-gray)' }}>
                {props.status}{props.title}
            </span>
            <span style={{ fontSize: '12px', opacity: '1', color: props.subTitleColor ?? 'var(--dcs-gray)' }}>
                {props.subTitle}
            </span>
        </div>
    );
};

export default MultiValueDisplay;
