import { useMutation } from "@tanstack/react-query";
import { Alert, Button, Form, Modal } from "antd";
import { useIdentity } from "context/auth";
import useAccountSummary from "hooks/useAccountSummary";
import { useAuthenticatedRequestCreator } from "hooks/useRequests";
import { useState } from "react";
import { centsToDollars, dollarsToCents } from "util/helpers";
import AmountFormItem from "../Payouts/RequestPayoutModal/PayoutMethodDetailForms/Inputs/AmountFormItem";

interface BreakerFundsTransferModalProps {
    breakerId: string;
    breakerUserName: string;
    breakerCreditBalanceInCents: number;
    onFundsTransfer: () => Promise<void>
}

enum TransferState {
    Start,
    Validated,
    Submitting,
    Success,
    Error
}

const useFundsTransfer = () => {
    const request = useAuthenticatedRequestCreator<void, { amountInCents: number, breakerId: string }>(
        (data) => ({
            method: "post",
            url: "/customer/breakers/transfer",
            data
        }), [400]
    );

    const { mutateAsync: transferFunds, isLoading: isWorking } = useMutation(request);

    return { transferFunds, isWorking };
}

const AlertState = (props: { state: TransferState, amountInCents?: number, breakerUserName: string }) => {
    const { state, amountInCents, breakerUserName } = props;

    if (state === TransferState.Validated) {
        return <Alert
            type="info"
            message={`You will be transfering ${centsToDollars(amountInCents!)} to your credit balance with ${breakerUserName}. Click 'Transfer Funds' to proceed, or 'Cancel' to abort the transfer.`}
        />;
    } else if (state === TransferState.Success) {
        return <Alert
            type="success"
            message="Transfer Complete!"
            showIcon
        />;
    } else if (state === TransferState.Error) {
        return <Alert
            type="error"
            message="Unable to process transfer, an unexpected error occurred."
            showIcon
        />;
    }

    // Start & Submitting have no alert
    return null;
}

const BreakerFundsTransferModal = ({ breakerId, breakerUserName, breakerCreditBalanceInCents, onFundsTransfer }: BreakerFundsTransferModalProps) => {
    const { accounts } = useIdentity();
    const { isLoading, data = { availableBalanceInCents: NaN }, refetch } = useAccountSummary(accounts[0]);
    const [open, setOpen] = useState(false);
    const [preFlight, setPreFlight] = useState<{ amountInCents: number }>();
    const [state, setState] = useState(() => TransferState.Start);
    const [form] = Form.useForm<{ amount: number }>();
    const { transferFunds, isWorking } = useFundsTransfer();

    const { availableBalanceInCents } = data;

    const noFunds = availableBalanceInCents === 0 && state === TransferState.Start;

    const reset = () => {
        setOpen(false);
        setPreFlight(undefined);
        setState(TransferState.Start);
        form.resetFields();
    }

    const success = async () => {
        await refetch();
        await onFundsTransfer();
        setState(TransferState.Success);
    }

    const submit = async () => {
        setState(TransferState.Submitting);

        try {
            await transferFunds({
                amountInCents: preFlight!.amountInCents,
                breakerId
            });

            await success();
        } catch {
            setState(TransferState.Error);
        }
    }

    const nextStep = async () => {
        switch (state) {
            case TransferState.Start: {
                const value = await form.validateFields();
                const amountInCents = dollarsToCents(value.amount.toString());

                setPreFlight({ amountInCents });
                setState(TransferState.Validated);
                break;
            }
            case TransferState.Validated: {
                await submit();
                break;
            }
            case TransferState.Success: {
                reset();
            }
        }
    }

    const okText = state === TransferState.Success
        ? "OK"
        : state === TransferState.Validated
            ? "Transfer Funds"
            : "Next";

    return <>
        <Button
            onClick={() => setOpen(true)}
            shape="circle"
            size="large"
            style={{ backgroundColor: 'var(--dcs-dark-green)', color: 'var(--dcs-white)' }}>
            +$
        </Button>
        <Modal
            title={`Transfer Funds to ${breakerUserName}`}
            open={open}
            onOk={nextStep}
            onCancel={reset}
            destroyOnClose
            maskClosable={false}
            keyboard={false}
            closable={false}
            confirmLoading={isLoading || isWorking}
            okButtonProps={{ hidden: noFunds }}
            cancelButtonProps={{ disabled: isLoading || isWorking, hidden: state === TransferState.Success }}
            okText={okText}
        >
            <Form form={form} preserve={false} disabled={!!preFlight || noFunds}>
                <div style={{ fontSize: '26px' }}>Available Balance: {centsToDollars(availableBalanceInCents)}</div>
                <p>Transfer funds from your available balance to your credit balance with {breakerUserName}, which is currently {centsToDollars(breakerCreditBalanceInCents)}.</p>

                <div style={{ marginTop: '20px' }}>
                    <AmountFormItem max={availableBalanceInCents} />

                    {noFunds && <Alert type="error" message="No funds available to transfer!" showIcon />}
                </div>
            </Form>

            <AlertState
                state={state}
                amountInCents={preFlight?.amountInCents}
                breakerUserName={breakerUserName}
            />
        </Modal>
    </>
};

export default BreakerFundsTransferModal;