import { useQuery } from "@tanstack/react-query";
import { Form, Select } from "antd";
import { useAuthenticatedRequest } from "hooks/useRequests";
import { BreakerCustomerStatus, CustomerBreakerStatus } from "models/breaker";
import { useEffect, useState } from "react";

interface BreakerLinkProps {
    userId: string;
}

const useBreakerLinksForUser = (userId: string) => {
    const getBreakers = useAuthenticatedRequest<CustomerBreakerStatus[]>({
        method: "get",
        url: `/customer/breakers/status/${userId}`
    });

    return useQuery(["customer", "breakers", "status", userId], getBreakers);
}

const useBreakerCustomers = (breakerUserId?: string) => {
    const getUsers = useAuthenticatedRequest<BreakerCustomerStatus[]>({
        method: "get",
        url: `/customer/breakers/users/${breakerUserId}`
    });

    return useQuery(["customer", "breakers", "users", breakerUserId], getUsers, {
        enabled: !!breakerUserId
    });
}

const BreakerCustomers = ({ breakerUserId, customerId, silentUserId, onChange }: {
    breakerUserId?: string,
    customerId: string,
    silentUserId?: string | null,
    onChange?: (value?: string | null) => void
}) => {
    const { data = [], isLoading } = useBreakerCustomers(breakerUserId);
    const [selected, setSelected] = useState<string | null | undefined>(silentUserId);

    useEffect(() => {
        if (!isLoading) {
            const current = data.find(x => x.linkedCustomerId === customerId);
            setSelected(current?.id ?? '');
        }
    }, [data, setSelected, isLoading, customerId]);

    const onUserChange = (newUserId: string) => {
        const value = newUserId === '' ? null : newUserId
        setSelected(newUserId);

        if (onChange) {
            onChange(value);
        }
    }

    return <Select
        placeholder="Selecting a breaker will let you link them to a silent user"
        loading={isLoading}
        options={[{
            label: "Not linked to the selected breaker",
            value: ''
        },
        ...data.map(x => ({
            label: `${x.userName}${x.linked ? ` - Linked to ${x.linkedCustomerUserName}` : ''}`,
            value: x.id,
            disabled: x.linked && x.linkedCustomerId !== customerId
        }))]}
        value={selected}
        onChange={onUserChange}
        disabled={!breakerUserId}
    />;
}

const BreakerLinks = ({ userId }: BreakerLinkProps) => {
    const { data = [], isLoading } = useBreakerLinksForUser(userId);
    const [breaker, setBreaker] = useState<CustomerBreakerStatus>();
    const [showHelp, setShowHelp] = useState(false);
    const form = Form.useFormInstance();

    const onBreakerChange = (breakerUserId: string) => {
        const selectedBreaker = data.find(x => x.breakerUserId === breakerUserId);

        if (selectedBreaker) {
            setBreaker(selectedBreaker);
            form.setFieldsValue({ silentUserId: selectedBreaker?.silentUser?.id ?? null });
        } else {
            setBreaker(undefined);
            form.setFieldsValue({ silentUserId: null });
        }
    }

    return <>
        <Form.Item label="Breaker Relationships" name="breakerUserId" rules={[{ required: false }]}>
            <Select
                placeholder="Select a Breaker"
                loading={isLoading}
                options={[{
                    label: "No Breaker Relationship Changes",
                    value: ''
                },
                ...data.map(x => ({
                    label: x.breakerUserName,
                    value: x.breakerUserId
                }))]}
                onChange={onBreakerChange}
            />
        </Form.Item>

        <Form.Item
            name="silentUserId"
            valuePropName="silentUserId"
            rules={[{ required: false }]}
            help={showHelp && "*First and last names will be updated to match the consignment user"}
        >
            <BreakerCustomers
                key={breaker?.breakerUserId ?? 'no-breaker'}
                breakerUserId={breaker?.breakerUserId}
                customerId={userId}
                onChange={v => setShowHelp(v !== null)}
            />
        </Form.Item>
    </>
};

export default BreakerLinks;