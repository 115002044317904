import { Box, BoxProps, forwardRef } from "@chakra-ui/react";
import Currency from "components/Currency";
import useAccountSummary from "hooks/useAccountSummary";
import SummaryDetail from "./ConfirmationDetails/SummaryDetail";

const AvailableBalance = forwardRef<BoxProps & { accountId: string }, typeof Box>((props, ref) => {
    // Suspends this component until we have the account summary, caught by our <Suspense>
    const { data } = useAccountSummary(props.accountId, { suspense: true });

    // All data values in cents the whole time
    const availableBalance = data?.availableBalanceInCents ?? 0;

    return (
        <SummaryDetail ref={ref} label="Available Balance" {...props}>
            <Currency cents={availableBalance} showZero />
        </SummaryDetail>
    );
});

export default AvailableBalance;
