import { useMutation } from "@tanstack/react-query";
import { ApplicationUser, SilentUser } from "models/applicationUsers";
import { PackageStatus } from "models/packages";
import { useAuthenticatedRequestCreator } from "./useRequests";

export interface PackageUpdate {
    id: string;
    customer: ApplicationUser;
    packageTypeId: string;
    packageStatus: PackageStatus;
    auctionEventId?: string;
    priority: boolean;
    processingFeeInCents: number | null;
    silentUser?: SilentUser | null;
}

const usePackageUpdate = () => {
    const update = useAuthenticatedRequestCreator<boolean, PackageUpdate>(model => ({
        method: "post",
        url: "/packages/update",
        data: {
            ...model,
            customerId: model.customer.id,
            accountId: model.silentUser?.accountId
        }
    }));

    const { mutateAsync: updatePackage } = useMutation(
        (model: PackageUpdate) => update(model)
    );

    return { updatePackage };
}

export default usePackageUpdate;