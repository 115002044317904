import { EditOutlined } from "@ant-design/icons";
import { Box, HStack, VStack } from "@chakra-ui/react";
import { Button, DatePicker, Form, Input, Modal, Select, Switch } from "antd";
import MultiValueDisplay from "components/MultiValueDisplay";
import usePackageTypeUpdate from "hooks/usePackageTypeUpdate";
import { DayOfWeek } from "models/common";
import { PackageType, PackageTypeWeight, PackageTypeWeightNames } from "models/packages";
import { DisplayDateFormat } from "models/websiteSettings";
import moment from "moment-timezone";
import { FC, useState } from "react";
import { centsToDollars, dollarsToCents } from "util/helpers";
import { Kind, bannerOrdinalValidator, feeValidator, toNullableNumber } from "./helpers";

interface PackageTypeForm {
    id: string;
    displayName: string;
    internalName: string;
    usesAmounts: boolean;
    leadTimeInDays: number | null;
    estUploadDate: moment.Moment | null;
    eventDayOfWeek: DayOfWeek | null;
    processingFee: string | null;
    processingFeeInCents: number;
    bannerOrdinal: number | null;
    sortingWeight: PackageTypeWeight;
    supportSilentAccounts: boolean;
    abbreviation: string;
}

const determineKind = (packageType: PackageType): Kind => {
    if (packageType.leadTimeInDays !== null) {
        return Kind.LeadTime;
    } else if (packageType.estimatedUploadDate !== null) {
        return Kind.OneTimeEvent;
    }

    return Kind.WeeklyEvent;
};

const EditModal: FC<{
    packageType: PackageType,
    onSuccess: () => Promise<any>
}> = ({ packageType, onSuccess }) => {
    const { updatePackageType } = usePackageTypeUpdate();
    const [visible, setVisible] = useState<boolean>(false);
    const [kind, setKind] = useState<Kind>(determineKind(packageType));
    const [working, setWorking] = useState<boolean>(false);
    const [form] = Form.useForm<PackageTypeForm>();

    const initialValues = {
        ...packageType,
        processingFee: packageType.processingFeeInCents > 0
            ? centsToDollars(packageType.processingFeeInCents, false)
            : null,
        estUploadDate: packageType.estimatedUploadDate
            ? moment(packageType.estimatedUploadDate)
            : null,
        eventDayOfWeek: packageType.eventDayOfWeek || DayOfWeek.Sunday,
        kind
    };

    const reset = () => {
        form.resetFields();
        setKind(determineKind(packageType));
        setVisible(false);
    }

    const submit = () => {
        form.validateFields().then(async (data) => {
            setWorking(true);

            try {
                await updatePackageType({
                    ...data,
                    estimatedUploadDate: data.estUploadDate
                        ? data.estUploadDate.toISOString()
                        : null,
                    eventDayOfWeek: (!data.leadTimeInDays && !data.estUploadDate)
                        ? data.eventDayOfWeek
                        : null
                });

                await onSuccess();
                setVisible(false);
            } finally {
                setWorking(false);
            }
        });
    }

    return <>
        <Button icon={<EditOutlined />} type="link" onClick={() => setVisible(true)} title="Edit Package Type" />
        <Modal
            open={visible}
            destroyOnClose
            closable={false}
            keyboard={false}
            maskClosable={false}
            onCancel={reset}
            onOk={submit}
            confirmLoading={working}
            cancelButtonProps={{ disabled: working }}
            okText={working ? "Saving..." : "Update"}
            title={packageType.isCoreType
                ? <MultiValueDisplay title={packageType.internalName} subTitle="This is a core package type, editing is limited" />
                : packageType.internalName}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={initialValues}
                preserve={false}
            >
                <HStack display='flex'>
                    <Form.Item
                        label="Internal Name (dcsports87 staff)"
                        name="internalName"
                        rules={[{ required: true, message: "Internal Name is required!" }]}
                        style={{ marginBottom: 0, width: '50%' }}>
                        <Input disabled={packageType.isCoreType} />
                    </Form.Item>

                    <Form.Item
                        label="Display Name (customers)"
                        name="displayName"
                        rules={[{ required: true, message: "Display Name is required!" }]}
                        style={{ width: '50%' }}>
                        <Input disabled={packageType.isCoreType} />
                    </Form.Item>
                </HStack>

                <HStack display='flex' mt={5} mb={5} alignItems='start'>
                    <VStack m={0} p={0} w='50%'>
                        <Box w='100%'>
                            <Form.Item label="Abbreviation" name="abbreviation" rules={[{ required: true }]} style={{ marginBottom: 0 }}>
                                <Input placeholder="For package QR codes" />
                            </Form.Item>
                        </Box>
                        <Box display='flex' justifyContent='start' alignItems='center' m='15px 0'>
                            <label style={{ marginRight: '10px' }}>Uses Package Amounts?</label>
                            <Form.Item
                                name="usesAmounts"
                                style={{ marginBottom: 0 }}
                                valuePropName="checked">
                                <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No" />
                            </Form.Item>
                        </Box>
                    </VStack>

                    <Box display='flex' w='50%'>
                        <Form.Item
                            label="What kind of package type is it?"
                            name="kind"
                            style={{ width: '100%' }}
                            rules={[{ required: true, message: "This is required!" }]}>
                            <Select
                                disabled={packageType.isCoreType}
                                onChange={setKind}
                                options={[
                                    { label: 'It\'s based on a lead time', value: Kind.LeadTime },
                                    { label: 'It\'s for a one-time event', value: Kind.OneTimeEvent },
                                    { label: 'It\'s a recurring event', value: Kind.WeeklyEvent },
                                ]}
                            />
                        </Form.Item>
                    </Box>
                </HStack>

                <HStack display='flex'>
                    <Form.Item
                        label="Banner Order #"
                        name="bannerOrdinal"
                        style={{ marginBottom: 0, width: '33%' }}
                        rules={[{ transform: toNullableNumber, validator: (r, v) => bannerOrdinalValidator(form, r, v) }]}>
                        <Input placeholder="Not Shown" type="number" />
                    </Form.Item>

                    <Form.Item
                        label="Processing Fee"
                        name="processingFee"
                        style={{ width: '33%' }}
                        rules={[{ transform: dollarsToCents, validator: (r, v) => feeValidator(form, r, v) }]}>
                        <Input prefix="$" placeholder="No Fee" />
                    </Form.Item>

                    {kind === Kind.LeadTime && <Form.Item
                        label="Lead Time in Days"
                        name="leadTimeInDays"
                        style={{ width: '33%' }}
                        rules={[{ required: true, message: "Lead Time is required!" }]}>
                        <Input type="number" />
                    </Form.Item>}

                    {kind === Kind.OneTimeEvent && <Form.Item
                        label="Est. Upload Date"
                        name="estUploadDate"
                        style={{ width: '33%' }}
                        rules={[{ required: true, message: "Date is required!" }]}>
                        <DatePicker
                            format={DisplayDateFormat}
                            disabled={packageType.isCoreType}
                            disabledDate={(date: moment.Moment) => date.isBefore(moment())} />
                    </Form.Item>}

                    {kind === Kind.WeeklyEvent && <Form.Item
                        label="Event Starts On"
                        name="eventDayOfWeek"
                        style={{ width: '33%' }}
                        rules={[{ required: true, message: "Day is required!" }]}>
                        <Select
                            options={[
                                { label: 'Sundays', value: DayOfWeek.Sunday },
                                { label: 'Mondays', value: DayOfWeek.Monday },
                                { label: 'Tuesdays', value: DayOfWeek.Tuesday },
                                { label: 'Wednesdays', value: DayOfWeek.Wednesday },
                                { label: 'Thursdays', value: DayOfWeek.Thursday },
                                { label: 'Fridays', value: DayOfWeek.Friday },
                                { label: 'Saturdays', value: DayOfWeek.Saturday },
                            ]}
                        />
                    </Form.Item>}
                </HStack>

                <Form.Item
                    label="Sorting Weight (For Incoming Packages)"
                    name="sortingWeight"
                    style={{ marginTop: '15px' }}>
                    <Select
                        options={[
                            { label: PackageTypeWeightNames[PackageTypeWeight.Premium], value: PackageTypeWeight.Premium },
                            { label: PackageTypeWeightNames[PackageTypeWeight.Priority], value: PackageTypeWeight.Priority },
                            { label: PackageTypeWeightNames[PackageTypeWeight.Standard], value: PackageTypeWeight.Standard }
                        ]}
                    />
                </Form.Item>

                <Form.Item
                    label="Restrict to silent accounts?"
                    help="If you select 'Yes', packages and the cards therein can only be assigned to silent accounts based on an account owner, typically a breaker"
                    name="supportSilentAccounts"
                    style={{ marginBottom: 0 }}
                    valuePropName="checked">
                    <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No" />
                </Form.Item>

                <Form.Item
                    hidden
                    name="processingFeeInCents">
                    <Input />
                </Form.Item>

                <Form.Item
                    hidden
                    name="id">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    </>;
};

export default EditModal;