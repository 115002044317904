import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import { BreakerCustomerSearch } from "components/EntityTableSearch";
import MultiValueDisplay from "components/MultiValueDisplay";
import FriendlyIdFilter from "components/staff/Packages/FriendlyIdFilter";
import StatusFilter from "components/staff/Packages/StatusFilter";
import { BreakerCustomerSearchResult, BreakerSubmission } from "models/breaker";
import { PackageStatus } from "models/packages";
import { useState } from "react";
import { localizedDate } from "util/helpers";
import { createCustomFilter } from "util/table";
import BreakerTable from "./BreakerTable";
import { createBreakerHooks } from "./createBreakerHooks";

const hooks = createBreakerHooks<BreakerSubmission>('/breaker/packages', {
    refreshOnCustomerEdit: true
});

const BreakerSubmissions = () => {
    const [filter, setFilter] = useState<{
        customer?: BreakerCustomerSearchResult;
        packageId?: number;
        status?: PackageStatus
    }>({});

    const columns: ColumnsType<BreakerSubmission> = [
        {
            title: <MultiValueDisplay title="User Name" subTitle="Email" titleColor="inherit" subTitleColor="inherit" />,
            dataIndex: "userName",
            key: "userId",
            ...createCustomFilter(
                BreakerCustomerSearch,
                (customer) => setFilter({ customer }),
                filter.customer?.id
            ),
            render: (userName, rec) => (
                <MultiValueDisplay
                    title={userName}
                    subTitle={rec.userEmail}
                />
            ),
        },
        {
            title: "Package ID",
            dataIndex: "friendlyId",
            sorter: true,
            ...createCustomFilter(
                FriendlyIdFilter,
                packageId => setFilter({ packageId }),
                filter.packageId
            ),
            render: (id) => (id === 0 ? "N/A" : id)
        },
        {
            title: "Status",
            dataIndex: "packageStatus",
            ...createCustomFilter(
                StatusFilter,
                status => setFilter({ status }),
                filter.status
            ),
            render: (status: PackageStatus) => PackageStatus[status]
        },
        {
            title: "Received Date",
            dataIndex: "receivedDate",
            key: "receivedDate",
            sorter: true,
            render: localizedDate,
            responsive: ['md']
        },
        {
            title: "Est. Upload Date",
            dataIndex: "estimatedUploadDate",
            key: "estimatedUploadDate",
            sorter: true,
            render: localizedDate
        },
        {
            title: "Upload Date",
            dataIndex: "uploadDate",
            key: "uploadDate",
            sorter: true,
            render: localizedDate
        },
        {
            title: "eBay Link",
            dataIndex: "ebayLink",
            key: "ebayLink",
            render: (link: string | null) =>
                link && (<Button type="link" target="_blank" href={link}>Active Listings</Button>)
        },
    ];

    return <BreakerTable
        hooks={hooks}
        columns={columns}
        noDataText="No packages have been received by dcsports87!"
    />;
}

export default BreakerSubmissions;