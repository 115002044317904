import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import Currency from "components/Currency";

export const TransactionSuccess = 204;
const MissingAccount = 404;
const InsufficientFunds = 422;
const UnknownError = 0;

const errorMap = new Map<number, string>([
    [MissingAccount, 'Unable to find either your breaks account, or the customer account!'],
    [InsufficientFunds, 'Insufficient funds to process the request!'],
    [UnknownError, 'An unknown error occurred!']
]);

const ResultsStep = (props: {
    toCustomerInCents: number
    active: boolean;
    status?: number;
}) => {
    const { active, status, toCustomerInCents } = props;

    if (!active || isNaN(toCustomerInCents) || status === undefined) {
        return <></>;
    }

    const ok = status === TransactionSuccess;

    return (
        <div id="adjustment-result">
            {ok && (
                <>
                    <div style={{ fontSize: '40px', color: 'var(--dcs-dark-green)' }}>
                        <CheckCircleOutlined />
                    </div>
                    <div className="content" style={{ marginBottom: '10px' }}>
                        An adjustment of <Currency cents={toCustomerInCents} /> has
                        been applied to the customer.
                    </div>
                    <div className="content">
                        Also, an adjustment of <Currency cents={-toCustomerInCents} /> has been applied to your
                        breaks account.
                    </div>
                </>
            )}

            {!ok && (
                <>
                    <div className="result error">
                        <WarningOutlined />
                    </div>
                    <div className="content">
                        {errorMap.get(status) ?? 'An unexpected error occurred!'}
                    </div>
                </>
            )}
        </div>
    );
};

export default ResultsStep;