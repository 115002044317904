export type AccountSummary = {
    availableBalanceInCents: number;
    moneyOnTheWayInCents: number;
    activeListingsInCents: number;
    activeListingsEbayLink: string;
};

export enum AccountType {
    Unknown = 0,
    Consignment = 1,
    Silent = 2,
    Holding = 3
}

export type CustomerAccount = {
    id: string;
    type: AccountType;
    active: boolean | null;
    email: string;
    firstName: string | null;
    lastName: string | null;
    userName: string;
    ownerUserName: string | null;
};

export type BreakerAccountSummary = {
    availableBalanceInCents: number;
    customerCreditInCents: number;
    moneyOnTheWayInCents: number;
    activeListingsInCents: number;
};
